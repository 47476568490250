<template>
  <div class="create-videos-tasks">
    <el-form style="max-width: 440px; width: 100%; margin-left: 80px" :inline="false">
      <el-form-item label="Title">
        <input class="main-input" v-model="title" placeholder="Enter title in English" />
      </el-form-item>
      <el-form-item label="Title (Español)">
        <input class="main-input" v-model="titleEs" placeholder="Enter title in español" />
      </el-form-item>
      <el-form-item label="Title (Português)">
        <input class="main-input" v-model="titlePt" placeholder="Enter title in português" />
      </el-form-item>

      <el-form-item label="Description">
        <textarea style="min-height: 192px" class="main-input" v-model="description" placeholder="Enter description in English"></textarea>
      </el-form-item>
      <el-form-item label="Description (Español)">
        <textarea style="min-height: 192px" class="main-input" v-model="descriptionEs" placeholder="Enter description in español"></textarea>
      </el-form-item>
      <el-form-item label="Description (Português)">
        <textarea style="min-height: 192px" class="main-input" v-model="descriptionPt" placeholder="Enter description in português"></textarea>
      </el-form-item>

      <el-form-item label="Price per 1000 views">
        <input class="main-input" type="number" v-model.number="price"/>
      </el-form-item>
      <el-form-item label="Company budget">
        <input class="main-input" type="number" v-model.number="companyBudget"/>
      </el-form-item>
      <el-form-item label="Currency">
        <el-select v-model="selectedCoin" placeholder="Select a coin">
          <el-option v-for="coin in coins" :key="coin.symbol" :label="coin.name" :value="coin">
            <img :src="coin.preview" alt="coin preview" class="coin-preview"/>
            {{ coin.name }} ({{ coin.symbol }})
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Preview Image">
        <el-upload
            class="upload-demo-image"
            :http-request="uploadImage"
            action=""
            multiple
            :limit="1"
            list-type="picture"
            :file-list="imageList">
          <el-button class="btn-main" size="small" type="primary">{{ $t('pick_image') }}</el-button>
        </el-upload>
      </el-form-item>
      <el-form-item label="Videos">
        <el-upload
            class="upload-demo"
            action=""
            multiple
            :http-request="uploadVideos"
            :file-list="videosList">
          <el-button class="btn-main" size="small" type="primary">{{ $t('pick_videos') }}</el-button>
        </el-upload>
      </el-form-item>
      <el-form-item>
        <el-checkbox v-model="age18">18+</el-checkbox>
        <el-checkbox v-model="age16">16+</el-checkbox>
      </el-form-item>
      <el-form-item label="Order number">
        <input class="main-input" type="number" v-model.number="order"/>
      </el-form-item>
      <el-form-item>
        <el-checkbox v-model="selectCountry" @change="handleCountrySelectChange">Select Country</el-checkbox>
        <el-select v-if="selectCountry" v-model="selectedCountries" multiple placeholder="Select countries">
          <el-option v-for="country in countries" :key="country.code" :label="country.name" :value="country.code">
            {{ country.name }}
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <div class="btn-solid" @click="submitForm">{{ route.params.id ? $t('update_task') :  $t('create_task') }}</div>
      </el-form-item>
    </el-form>
    <div v-if="videos.length" class="video-thumb">
      <h3>Video Thumbnails:</h3>
      <div class="thumbnails-row">
        <div v-for="(video, index) in videos" :key="index" class="thumbnail">
          <img :src="video.preview_href" alt="Video Thumbnail"/>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { onMounted, ref } from 'vue';
import { ElMessage } from 'element-plus';
import { db, storage } from '@/firebase/init';
import { addDoc, collection, doc, getDoc, getDocs, updateDoc } from 'firebase/firestore';
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import { useRoute, useRouter } from "vue-router";

export default {
  setup() {
    const title = ref('');
    const titleEs = ref('');
    const titlePt = ref('');
    const description = ref('');
    const descriptionEs = ref('');
    const descriptionPt = ref('');

    const price = ref(null);
    const companyBudget = ref(null);
    const preview = ref('');
    const videos = ref([]);
    const videosOld = ref([]);

    const selectedCoin = ref(null);
    const order = ref(0);
    const age18 = ref(false);
    const age16 = ref(false);
    const selectCountry = ref(false);
    const selectedCountries = ref([]);
    const coins = ref([]);
    const countries = ref([
      { code: 'US', name: 'United States' },
      { code: 'CA', name: 'Canada' },
      // Add other countries as needed
    ]);

    const router = useRouter();
    const route = useRoute();

    const imageList = ref([]);
    const videosList = ref([]);

    const handleCountrySelectChange = (value) => {
      if (!value) {
        selectedCountries.value = [];
      }
    };

    const uploadImage = async ({ file }) => {
      const fileUID = uuidv4();
      const imageRef = storageRef(storage, `images/${fileUID + file.name}`);
      const snapshot = await uploadBytes(imageRef, file);
      const downloadURL = await getDownloadURL(snapshot.ref);
      preview.value = downloadURL;
    };

    const uploadVideos = async ({ file }) => {
      const fileUID = uuidv4();
      const videoRef = storageRef(storage, `videos/${fileUID + file.name}`);
      const snapshot = await uploadBytes(videoRef, file);
      const downloadURL = await getDownloadURL(snapshot.ref);

      // Generate thumbnail and add to videos array
      await generateThumbnail(file, downloadURL, fileUID);
    };

    const generateThumbnail = async (videoFile, videoHref, videoId) => {
      const thumbnailURL = await generateThumbnailImage(videoFile);
      const imageRef = storageRef(storage, `thumbnails/${videoId}.jpg`);
      const response = await fetch(thumbnailURL);
      const blob = await response.blob();
      await uploadBytes(imageRef, blob);
      const downloadThumbnailURL = await getDownloadURL(imageRef);

      // Push video object with preview thumbnail URL
      videos.value.push({ id: videoId, href: videoHref, preview_href: downloadThumbnailURL });
    };

    const generateThumbnailImage = (videoFile) => {
      return new Promise((resolve) => {
        const video = document.createElement('video');
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        video.preload = 'metadata';
        video.src = URL.createObjectURL(videoFile);

        video.onloadedmetadata = () => {
          video.currentTime = 1; // set time to 1 second
        };

        video.onseeked = () => {
          const scale = 380 / video.videoWidth;
          canvas.width = 380;
          canvas.height = video.videoHeight * scale;
          context.drawImage(video, 0, 0, canvas.width, canvas.height);

          canvas.toBlob(
              (blob) => {
                const thumbnailURL = URL.createObjectURL(blob);
                URL.revokeObjectURL(video.src); // free memory
                resolve(thumbnailURL);
              },
              'image/jpeg',
              0.8
          );
        };
      });
    };

    const fetchTask = async (id) => {
      const taskDoc = await getDoc(doc(db, 'tasks', id));
      if (taskDoc.exists()) {
        const taskData = taskDoc.data();

        // Populate fields, keeping 'title' and 'description' as strings
        title.value = taskData.title || '';
        titleEs.value = taskData.titleEs || '';
        titlePt.value = taskData.titlePt || '';
        description.value = taskData.description || '';
        descriptionEs.value = taskData.descriptionEs || '';
        descriptionPt.value = taskData.descriptionPt || '';

        price.value = taskData.price;
        companyBudget.value = taskData.companyBudget;
        preview.value = taskData.preview;
        videosOld.value = taskData.videos;
        order.value = taskData.order || 0;
        selectedCoin.value = taskData.coin;
        age18.value = taskData.age18;
        age16.value = taskData.age16;
        selectedCountries.value = taskData.selectedCountries;
      } else {
        ElMessage.error('Task not found');
      }
    };

    const translate = async (text, targetLang) => {
      // Simulate translation
      // In practice, replace this with an actual translation API call
      if (targetLang === 'es') {
        return 'Traducción al español de: ' + text;
      } else if (targetLang === 'pt') {
        return 'Tradução em português de: ' + text;
      }
      return text;
    };

    const submitForm = async () => {
      // Auto-translate if Spanish and Portuguese fields are empty
      if (!titleEs.value) {
        titleEs.value = await translate(title.value, 'es');
      }
      if (!titlePt.value) {
        titlePt.value = await translate(title.value, 'pt');
      }
      if (!descriptionEs.value) {
        descriptionEs.value = await translate(description.value, 'es');
      }
      if (!descriptionPt.value) {
        descriptionPt.value = await translate(description.value, 'pt');
      }

      const formData = {
        title: title.value,
        titleEs: titleEs.value,
        titlePt: titlePt.value,
        description: description.value,
        descriptionEs: descriptionEs.value,
        descriptionPt: descriptionPt.value,
        price: price.value,
        order: order.value,
        currentUsers: 0,
        companyBudget: companyBudget.value,
        preview: preview.value || null,
        videos: videos.value.length ? videos.value : videosOld.value,
        coin: selectedCoin.value || null,
        age18: age18.value || false,
        age16: age16.value || false,
        selectedCountries: selectedCountries.value || null,
      };

      try {
        if (route.params.id) {
          await updateDoc(doc(db, 'tasks', route.params.id), formData);
          ElMessage.success('Task updated successfully');
        } else {
          formData.hide = true;
          await addDoc(collection(db, 'tasks'), formData);
          ElMessage.success('Task created successfully');
        }
        router.push('/admin');
      } catch (error) {
        console.error(error);
        ElMessage.error('Failed to submit task');
      }
    };

    onMounted(async () => {
      if (route.params.id) {
        fetchTask(route.params.id);
      }

      const querySnapshot = await getDocs(collection(db, 'coins'));
      coins.value = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    });

    return {
      title,
      titleEs,
      titlePt,
      description,
      descriptionEs,
      descriptionPt,
      price,
      companyBudget,
      preview,
      videos,
      route,
      order,
      imageList,
      videosList,
      uploadImage,
      uploadVideos,
      submitForm,
      selectedCoin,
      age18,
      age16,
      selectCountry,
      selectedCountries,
      coins,
      countries,
      handleCountrySelectChange,
    };
  },
};
</script>


<style lang="scss">
.btn-solid {
  padding: 0 16px;
}

.thumbnail {
  margin: 10px 0;
}

.thumbnail img {
  max-width: 100%;
  height: auto;
}

.thumbnails-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 16px 16px;
  box-sizing: border-box;
  img {
    border-radius: 12px;
  }
}

.video-thumb {
  margin-left: 32px;
}

.create-videos-tasks {
  display: flex;
}

.coin-preview {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
</style>
