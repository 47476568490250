<template>
  <div class="offer-card">
    <div class="offer-container">
      <div class="offer-preview-img">
      <img class="offer-preview" :src="card.preview"/>
      </div>
      <div class="offer-body">
        <div class="offer-title-container">
          <div class="offer-title">{{ localizedTitle }}</div>
        </div>
        <div class="container-description" v-if="isAuth">
          <span class="offer-description" v-html="localizedDescription"></span>
        </div>
        <div class="offer-footer">
          <div class="price-offer-container">
            <div class="offer-amount">
              <img v-if="!card?.coin" :class="['usdt-offer', card?.coin?.name]" src="@/assets/usdt.svg">
              <img v-else  :class="['usdt-offer', card?.coin?.name]" :src="card?.coin?.preview">

              <span class="amount-sum">{{ card.price }} {{card?.coin?.name || 'POINT'}}</span>
            </div>
            <span class="small">{{$t('per_1000_views')}}</span>
          </div>
          <router-link :to="`/offer/${card.id}`"
                       data-tag
                       data-cat="user-action" data-label="offer-card-click" :data-value="card.title"
          >
            <button class="btn-solid view-offer-card-main">{{$t('view_offer')}}</button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

export default {
  name: 'OfferCard',
  props: {
    isAuth: Boolean,
    card: {
      type: Object,
      default: () => ({
        id: '',
        title: '',
        titleEs: '',
        titlePt: '',
        description: '',
        descriptionEs: '',
        descriptionPt: '',
        price: 0,
        coin: null,
        preview: '',
        currentUsers: 0,
        maxUsers: 0,
      }),
    },
  },
  setup(props) {
    const { locale } = useI18n();

    const localizedTitle = computed(() => {
      let titleText = null

      if (locale.value === 'es' && props.card.titleEs) {
        titleText =  props.card.titleEs;
      } else if (locale.value === 'pt' && props.card.titlePt) {
        titleText = props.card.titlePt;
      } else {
        titleText = props.card.title;
      }
      if (!titleText) {
        return  props.card.title;
      }

      return titleText

    });

    const localizedDescription = computed(() => {
      let descriptionText = '';
      if (locale.value === 'es' && props.card.descriptionEs) {
        descriptionText = props.card.descriptionEs;
      } else if (locale.value === 'pt' && props.card.descriptionPt) {
        descriptionText = props.card.descriptionPt;
      } else {
        descriptionText = props.card.description; // Default to English
      }


      if (!descriptionText) {
        return  props.card.description;
      }
      // Format URLs in the description
      const urlPattern = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig; // eslint-disable-line
      return descriptionText.replace(
          urlPattern,
          '<a class="link-description" href="$1" target="_blank">$1</a>'
      );
    });

    return {
      localizedTitle,
      localizedDescription,
    };
  },
};
</script>
<style scoped lang="scss">
.offer-container, .container-description {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
}
.offer-card {
  max-width: 100%;
  overflow: hidden;
  border-radius: 18px;
  padding-bottom: 12px;
  background: rgba(255,255,255, 0.12);

  .offer-preview {
    width: 100%;
    height: 165px;
    object-fit: cover;
  }

  .offer-title {
    color: #fff;
    font-size: 18px;
    max-width: 80%;
    font-family: Roboto;
    font-weight: 600;
  }

  .offer-title-container {
    justify-content: flex-end;
    flex-direction: column;
    padding-bottom: 12px;
    box-sizing: border-box;
    display: flex;
    margin-top: -101px;
    height: 100px;
    background: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
  }

  .offer-body {
    padding: 0 12px 4px 12px;
    display: flex;
    justify-content: space-between;
    flex: 1;
    flex-direction: column;
  }

  .offer-description, .offer-label {
    display: inline;
    font-size: 14px;
    color: var(--tg-second-color);
    font-weight: 300;
    font-family: Roboto;
  }

  .offer-description {
    margin-top: 12px;
    margin-bottom: 8px;
    overflow: hidden;
    white-space: pre-wrap;
    height: 42px;
  }

  .container-description {
    border-bottom: 1px solid rgba(255,255,255,0.15);
    padding-bottom: 8px;
    margin-bottom: -4px;
  }


  .offer-participants {
    padding: 12px 0;
    border-top: 1px solid var(--tg-second-dark);
    border-bottom: 1px solid var(--tg-second-dark);
    margin-top: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;

  }

  .participants-count {
    color: var(--tg-heading-font-color);
    font-weight: 600;
    font-size: 15px;
    font-family: Roboto;
  }

  .offer-footer {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-top: 16px;

    .btn-solid {
      font-size: 14px;
      padding-left: 12px;
      width: 100%;
      padding-right: 12px;
      height: 48px;
      cursor: pointer;
    }
  }


  .offer-amount {
    color: var(--tg-heading-font-color);
    display: flex;
    align-items: center;
  }

  .hot-participant {
    width: 18px;
    height: 18px;
    margin-right: 2px;
  }

  .usdt-offer {
    width: 22px;
    height: 22px;
    margin-right: 8px;
  }
}

.participants-count {
  display: flex;
  align-items: center;
}

.small {
  font-size: 12px;
  font-weight: 300;
  font-family: Roboto;
  margin-left: 28px;
  color: var(--tg-heading-font-color);
}

.amount-sum {
  font-size: 20px;
}

.price-offer-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}
@media only screen and (max-width: 600px) {
  .offer-title {
    font-size: 14px !important;
  }

  .offer-card .offer-preview {
    height: 120px !important;
  }

  .offer-card {
    .small {
      margin-left: 0;
    }
  }

  .price-offer-container {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .offer-card .offer-footer {
    margin-top: 0;
    padding-top: 8px;
    border-top: 0;
  }

  .offer-amount {
    font-size: 20px !important;
  }

  .price-offer-container {
    margin-bottom: 7px;
  }

  .small {
    margin: 4px 0;
  }
}


.view-offer-card-main {
  border: 1px solid rgba(255,255,255, 0.2);
  background: rgba(0,0,0,0);
  height: 48px;
  color: #DB8DFF;

}
</style>
