<template>
  <div class="connect-modal" @click="handleClose">
    <div class="connect-container" @click.stop>
      <div class="content">
        <h2>Increase your earnings!</h2>

        <div class="container">
          <img src="@/assets/tiktok-verif.svg" class="icon"/>
          <div class="txt-container">
            <strong>Approve requests on TikTok</strong>
            <span>Make sure all requests are reviewed and approved to keep
          engagement high.</span>
          </div>
        </div>
        <div class="container">
          <img src="@/assets/clock.svg" class="icon"/>

          <div class="txt-container">
            <strong>Post 5 times a day</strong>
            <span>Publish 5 videos a day and receive a daily reward. Regular posts help maintain your audience and increase views.</span>
          </div>
        </div>
        <div class="container">
          <img src="@/assets/money.svg" class="icon"/>

          <div class="txt-container">
            <strong>Earn money</strong>
            <span>Your activity generates income — don’t miss the chance to make more!</span></div>
        </div>
        <button class="btn-solid modal-info-ok-btn" @click="close">ok</button>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "NotificationModal",
  setup(props, {emit}) {

    const close = () => {
      emit('close');
    };

    const handleClose = (event) => {
      if (event.target === event.currentTarget) {
        close();
      }
    };

    return {handleClose, close};
  }
});
</script>

<style scoped lang="scss">
.connect-container {
  background: #fefefe;
  border-radius: 20px;
  width: calc(100% - 16px);
  max-width: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 320px;
  flex-direction: column;
  color: #000;
}

h6 {
  font-size: 24px;
}

.connect-modal {
  z-index: 999999;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

h2 {
  margin-bottom: 32px;
  margin-top: 32px;
  text-align: center;
  color: #000;
}

.container {
  border: 1px solid #eee;
  background: #fff;
  padding: 16px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.icon {
  width: 64px;
  height: 64px;
  margin-right: 12px;
}

.connect-btn {
  background: rgb(21, 23, 38);
  width: calc(100% - 32px);
  display: flex;
  max-width: 280px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  margin-bottom: 16px;
  border-radius: 16px;
  cursor: pointer;

  &:hover {
    background: rgb(21, 23, 54);
  }

  img {
    margin-right: 12px;
    margin-left: -12px;
  }
}

.connect-icon {
  width: 36px;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 16px;

  //span {
  //  font-size: 15px;
  //  margin-top: 6px;
  //  margin-bottom: 6px;
  //  background: var(--tg-primary-bg);
  //  border-radius: 12px;
  //  padding: 16px 16px;
  //  text-align: left;
  //}
}

.btn-solid {
  margin-top: 16px;
}

.txt-container {
  display: flex;
  flex-direction: column;
  strong {
    font-size: 16px;
  }
  span {
    font-size: 15px;
    color: #212529;
  }
}
</style>
