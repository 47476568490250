<template>
  <div class="create-videos-tasks">
    <el-form style="max-width: 440px; width: 100%; margin-left: 80px" :inline="false">
      <el-form-item label="Name">
        <input class="main-input" v-model="name"/>
      </el-form-item>
      <el-form-item label="Symbol">
        <input class="main-input" type="textarea" v-model="symbol"/>
      </el-form-item>
      <el-form-item label="Preview Image">
        <el-upload
            class="upload-demo-image"
            :http-request="uploadImage"
            action=""
            multiple
            :limit="1"
            list-type="picture"
            :file-list="imageList">
          <el-button class="btn-main" size="small" type="primary">{{ $t('pick_image') }}</el-button>
        </el-upload>
      </el-form-item>
      <el-form-item label="Min withdraw amount">
        <input class="main-input" type="number" v-model="minAmount"/>
      </el-form-item>
      <el-form-item label="Currency Type">
        <el-select v-model="currencyType" placeholder="Select currency type">
          <el-option label="USDT" value="usdt"></el-option>
          <el-option label="Fiat" value="fiat"></el-option>
          <el-option label="Token" value="token"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <div class="btn-solid" @click="submitForm">{{ route.params.id ? $t('update coin') :  $t('create coin') }}</div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import { ElMessage } from 'element-plus';
import { db, storage } from '@/firebase/init';
import { addDoc, collection, doc, getDoc, updateDoc } from 'firebase/firestore';
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import {useRoute, useRouter} from "vue-router";

export default {
  setup() {
    const name = ref('');
    const symbol = ref('');
    const preview = ref('');
    const minAmount = ref(0)
    const currencyType = ref('token');
    const imageList = ref([]);

    const router = useRouter();

    const route = useRoute();

    const handleImageChange = (file) => {
      imageList.value = [file.file];
    };

    const uploadImage = async ({ file }) => {
      const fileUID = uuidv4();
      const imageRef = storageRef(storage, `coins/${fileUID + file.name}`);
      const snapshot = await uploadBytes(imageRef, file);
      const downloadURL = await getDownloadURL(snapshot.ref);
      preview.value = downloadURL;
      // imageList.value = [];
    };

    const fetchTask = async (id) => {
      const taskDoc = await getDoc(doc(db, 'coins', id));
      if (taskDoc.exists()) {
        const taskData = taskDoc.data();
        name.value = taskData.name;
        symbol.value = taskData.symbol;
        preview.value = taskData.preview;
        currencyType.value = taskData.currencyType || 'token';
        minAmount.value = taskData.minAmount;
      } else {
        ElMessage.error('Coin not found');
      }
    };

    const submitForm = async () => {
      const formData = {
        name: name.value,
        symbol: symbol.value,
        currencyType: currencyType.value,
        preview: preview.value || null,
        minAmount: minAmount.value || 0
      };

      try {
        if (route.params.id) {
          await updateDoc(doc(db, 'coins', route.params.id), formData);
          ElMessage.success('Coin updated successfully');
        } else {
          await addDoc(collection(db, 'coins'), formData);
          ElMessage.success('Coin created successfully');
        }
        router.push('/admin/coins');
      } catch (error) {
        ElMessage.error('Failed to submit coin');
      }
    };

    onMounted(() => {
      if (route.params.id) {
        fetchTask(route.params.id);
      }
    });

    return {
      name, symbol, route, minAmount,
      imageList, handleImageChange, uploadImage, submitForm,
      currencyType,
    };
  },
};
</script>

<style lang="scss">
.btn-solid {
  padding: 0 16px;
}

.thumbnail {
  margin: 10px 0;
}

.thumbnail img {
  max-width: 100%;
  height: auto;
}

.thumbnails-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 16px 16px;
  box-sizing: border-box;
  img {
    border-radius: 12px;
  }
}

.video-thumb {
  margin-left: 32px;
}

.create-videos-tasks {
  display: flex;
}
</style>
