<template>
  <ul class="navigation">
    <li v-for="menu in filteredMenu" :key="menu.id" :class="{ 'menu-item-has-children': menu.has_dropdown }">
      <router-link :to="menu.link" :class="[{ 'section-link': true, 'active': isActiveMenuItem(menu.link) }, `menu-${menu.title}-link`]">
        {{ $t(menu.title) }}
      </router-link>
      <ul v-if="menu.has_dropdown && menu.sub_menus" class="sub-menu">
        <li v-for="(sub_m, i) in menu.sub_menus" :key="i">
          <router-link :to="sub_m.link" :class="[{ 'active': isActiveSubMenuItem(sub_m.link) }, `menu-${sub_m.title}-link`]">
            {{ $t(sub_m.title) }}
          </router-link>
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
import {  computed } from 'vue';
import { useRoute } from 'vue-router';
import menuData from './MenuData';

export default {
  name: 'NavMenuTwo',
  setup() {
    const route = useRoute();

    const isActiveMenuItem = (menuLink) => {
      return route.path === menuLink;
    };

    const isActiveSubMenuItem = (subMenuLink) => {
      return route.path === subMenuLink;
    };

    const filteredMenu = computed(() => {
      return menuData.filter((items) => items.page === 'nav_2');
    });

    return {
      isActiveMenuItem,
      isActiveSubMenuItem,
      filteredMenu
    };
  }
};
</script>
