const menu_data= [
    {
        id: 1,
        page:"nav_2",
        has_dropdown: false,
        title: "offers",
        link: "/offers",
    },
    {
        id: 5,
        page:"nav_2",
        has_dropdown: false,
        title: "profile",
        link: "/profile",
    },
    {
        id: 2,
        page:"nav_2",
        has_dropdown: false,
        title: "wallet",
        link: "/wallet",
    },
    {
        id: 3,
        page:"nav_2",
        has_dropdown: false,
        title: "settings",
        link: "/settings",
    },
    {
        id: 6,
        page:"nav_2",
        has_dropdown: false,
        title: "referral",
        link: "/referral",
    },
];
export default menu_data;
