<template>
  <footer class="footer-wrapper footer-layout2 pt-50 mt-30">
    <div class="container">
      <div class="row justify-content-between">
        <div class="col-xl-auto col-lg-6 order-xl-1">
          <div class="widget footer-widget">
            <div class="widget-about">
              <div class="footer-logo">
                <router-link to="/">
                  <img src="@/assets/logo.svg" class="logo-white"  style="height: 35px;" alt="StreamSale" />
                  <img src="@/assets/logo-black.svg" class="logo-black" style="height: 35px;" alt="StreamSale" />

                </router-link>
              </div>
              <p class="about-text">{{$t('footer.title')}}</p>
              <div class="social-btn style2">
                <a target="_blank" href="https://www.facebook.com/streamsale.xyz"><i class="fab fa-facebook-f"></i></a>
                <a target="_blank" href="https://x.com/StreamSale_xyz">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path
                        d="M10.0596 7.34522L15.8879 0.570312H14.5068L9.44607 6.45287L5.40411 0.570312H0.742188L6.85442 9.46578L0.742188 16.5703H2.12338L7.4676 10.3581L11.7362 16.5703H16.3981L10.0593 7.34522H10.0596ZM8.16787 9.54415L7.54857 8.65836L2.62104 1.61005H4.74248L8.71905 7.29827L9.33834 8.18405L14.5074 15.5779H12.386L8.16787 9.54449V9.54415Z"
                        fill="currentColor"
                    ></path>
                  </svg>
                </a>
                <a target="_blank" href="https://instagram.com/streamsale_fin"><i class="fab fa-instagram"></i></a>
                <a target="_blank" href="https://t.me/streamsale_xyz"><i class="fab fa-telegram"></i></a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-auto col-lg-6 order-xl-3">
          <div class="footer-widget widget-newsletter">
            <h3 class="fw-title">{{$t('footer.sub')}}</h3>
            <p class="newsletter-text">{{$t('footer.sub_title')}}</p>
            <form @submit.prevent="subscribe" class="newsletter-form">
              <div class="form-group">
                <input class="form-control" type="email" v-model="email" :placeholder="$t('footer.your_email')" />
              </div>
              <button type="submit" class="btn btn5">Subscribe</button>
            </form>
          </div>
        </div>

        <div class="col-xl-auto col-lg-6 order-xl-2">
          <div class="footer-widget widget-contact">
            <h3 class="fw-title">{{$t('footer.contact')}}</h3>
            <div class="contact-info-link">
              <a href="mailto:StreamSale@company.com">office@streamsale.xyz</a>
            </div>
            <p class="copyright-text" v-html="$t('footer.copy')"></p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterMain",
  data() {
    return {
      email: ''
    };
  },
  methods: {
    subscribe() {
      // Добавьте логику подписки
      console.log(this.email);
    }
  }
};
</script>
