<template>
  <div :class="{ 'mobile-menu-visible': isActive }">
    <div class="mobile-menu">
      <nav class="menu-box">
        <div @click="setIsActive(false)" class="close-btn mobile-menu-open-btn">
          <i class="fas fa-times"></i>
        </div>
        <div class="nav-logo">
          <router-link to="/">
            <img src="@/assets/logo.svg" alt="Logo" />
          </router-link>
        </div>
        <div class="menu-outer">
          <MobileMenu v-if="isUserEmail" @closeMenu="setIsActive(false)" />
        </div>
        <div class="lang-switcher lang-switcher-menu-dark">
          <LanguageSwitcher/>
        </div>
      </nav>
    </div>
    <div @click="setIsActive(false)" class="menu-backdrop"></div>
  </div>
</template>

<script>
import MobileMenu from './MobileMenu';
import LanguageSwitcher from "@/components/LanguageSwitcher";

export default {
  name: 'SidebarMenu',
  props: {
    isUserEmail: {
      type: Boolean,
      default: false
    },
    isActive: {
      type: Boolean,
      default: false
    },
    setIsActive: {
      type: Function,
      required: true
    }
  },
  components: {
    LanguageSwitcher,
    MobileMenu
  },
};
</script>
<style scoped>
.lang-switcher {
  padding: 0 24px;
  margin-top: 32px;
  box-sizing: border-box;
}
</style>
