<template>
  <div class="task-view-page" v-loading="loading">
    <div class="users-stat">
      <h3>{{ profile.username }}</h3>

      <div class="statistic">
        <div class="static-li">
          <strong> {{ stat.videos || '-' }}</strong>
          <span>{{ $t('video_count') }}</span>
        </div>

        <div class="static-li">
          <strong>{{ stat.earn || '-' }}</strong>
          <span>{{ $t('Total earn') }}</span>
        </div>

        <div class="static-li">
          <strong>{{ stat.withdrawal || '-' }}</strong>
          <span>{{ $t('Total withdrawal') }}</span>

        </div>

        <div class="static-li">
          <strong>{{ stat.currentBalance || '-' }}</strong>
          <span>{{ $t('Current balance') }}</span>

        </div>

        <div class="static-li">
          <strong>{{ totalViews }}</strong>
          <span>{{ $t('total views') }}</span>
        </div>
      </div>



      <h3 style="margin-top: 42px">Statistics Chart</h3>


      <div style="margin-bottom: 20px;">
        <el-date-picker
            v-model="dateRangeForStats"
            type="daterange"
            range-separator="To"
            start-placeholder="Start date"
            end-placeholder="End date"
            @change="applyFilters"
        />
      </div>
      <div style="margin-bottom: 20px;">
        <el-radio-group v-model="selectedMetric" @change="updateChart">
          <el-radio-button label="Videos">Videos</el-radio-button>
          <el-radio-button label="Views">Views</el-radio-button>
          <el-radio-button label="Earn">Earn</el-radio-button>
        </el-radio-group>
      </div>

      <div>
        <canvas  class="videosChart" id="dailyStatsChart" style="margin-top: 20px;"></canvas>
      </div>

      <h3 style="margin-top: 42px">Offers</h3>
      <el-table class="dark-theme" :data="videos" style="width: 100%; margin-top: 20px">
        <el-table-column type="expand">
          <template #default="props">
            <div m="4">
              <el-table :data="props.row.videos">
                <el-table-column prop="share_url" label="Video url" v-slot="{row}">
                  <a target="_blank" :href="row.share_url"> {{
                      row.share_url.split('?')[0]
                    }}
                  </a>
                </el-table-column>
                <el-table-column prop="profile" label="Tiktok profile" v-slot="{row}">
                  <a target="_blank" :href="`https://www.tiktok.com/@${row.profile}`"> {{
                      `https://www.tiktok.com/@${row.profile}`
                    }}
                  </a>
                </el-table-column>
                <el-table-column prop="share_count" label="Shares"></el-table-column>
                <el-table-column prop="comment_count" label="Comments"></el-table-column>
                <el-table-column prop="like_count" label="Likes"></el-table-column>
                <el-table-column sortable prop="view_count" label="Views"></el-table-column>
                <el-table-column sortable prop="earn" label="Total earn"></el-table-column>
                <el-table-column sortable prop="datetime" label="Date"></el-table-column>

              </el-table>
            </div>
          </template>
        </el-table-column>
        <el-table-column
            label="Offer"
            v-slot="{ row }"
        >
          <a v-if="row.task" :href="`/admin/task/${row.task.id}`">{{ row.task.title }}</a>
        </el-table-column>
        <el-table-column prop="videos_count" label="Videos"></el-table-column>

        <el-table-column prop="share_count" label="Shares"></el-table-column>
        <el-table-column prop="comment_count" label="Comments"></el-table-column>
        <el-table-column prop="like_count" label="Likes"></el-table-column>
        <el-table-column sortable prop="view_count" label="Views"></el-table-column>
        <el-table-column sortable prop="earn" label="Total earn"></el-table-column>

      </el-table>


      <h3 style="margin-top: 42px">Profiles</h3>
      <el-table class="dark-theme" :data="tiktokProfiles" style="width: 100%; margin-top: 20px">
        <el-table-column type="expand">
          <template #default="props">
            <div m="4">
              <el-table :data="props.row.videos">
                <el-table-column prop="share_url" label="Video url" v-slot="{row}">
                  <a target="_blank" :href="row.share_url"> {{
                      row.share_url.split('?')[0]
                    }}
                  </a>
                </el-table-column>
                <el-table-column
                    label="Offer"
                    v-slot="{ row }"
                >
                  <a v-if="row.task" :href="`/admin/task/${row.task.id}`">{{ row.task.title }}</a>
                </el-table-column>
                <el-table-column prop="share_count" label="Shares"></el-table-column>
                <el-table-column prop="comment_count" label="Comments"></el-table-column>
                <el-table-column prop="like_count" label="Likes"></el-table-column>
                <el-table-column sortable prop="view_count" label="Views"></el-table-column>
                <el-table-column sortable prop="earn" label="Total earn"></el-table-column>
                <el-table-column sortable prop="datetime" label="Date"></el-table-column>

              </el-table>
            </div>
          </template>
        </el-table-column>
        <el-table-column
            width="84"
            label="Avatar"
            v-slot="{ row }"
        >
          <img class="avatar-tiktok" :src="row.avatar_url">
        </el-table-column>
        <el-table-column prop="username" label="Username"></el-table-column>

        <el-table-column prop="share_count" label="Tiktok url" v-slot="{row}">
          <a target="_blank" :href="`https://www.tiktok.com/@${row.username}`"> {{
              `https://www.tiktok.com/@${row.username}`
            }}
          </a>
        </el-table-column>
        <el-table-column prop="videos_count" label="Videos"></el-table-column>

        <el-table-column prop="comment_count" label="Comments"></el-table-column>
        <el-table-column prop="like_count" label="Likes"></el-table-column>
        <el-table-column sortable prop="view_count" label="Views"></el-table-column>
        <el-table-column sortable prop="earn" label="Total earn" v-slot="{row}">
          {{ parseFloat(Number(row.earn).toFixed(2)) }}
        </el-table-column>

      </el-table>


      <h3 style="margin-top: 42px">Transactions</h3>
      <el-table class="dark-theme" :data="transactions" style="width: 100%; margin-top: 20px">

        <el-table-column prop="status" label="Status"></el-table-column>
        <el-table-column prop="info.address" label="Address"></el-table-column>
        <el-table-column sortable prop="sum" label="Sum">
        </el-table-column>
        <el-table-column sortable prop="datetime" label="Date">
        </el-table-column>

      </el-table>
    </div>
  </div>
</template>

<script>
import {onMounted, ref} from 'vue';
import {useRoute} from "vue-router";

import {fetchTikTokProfiles, fetchTikTokProfilesByUsername, getBalances, getWithdrawal} from "@/firebase/queries";
import {collection, getDocs, query, where} from "firebase/firestore";
import {db} from "@/firebase/init";
import {orderBy, sortBy} from "lodash";
import dayjs from "dayjs";
import Chart from "chart.js/auto";
export default {
  setup() {
    const profile = ref({});
    const tiktokProfiles = ref([]);

    const stat = ref({
      videos: 0,
      profiles: 0,
      currentBalance: 0,
      earn: 0,
      withdrawal: 0,
    });
    const route = useRoute();
    const videos = ref([])
    const loading = ref(false)
    const totalViews = ref(0)
    const transactions = ref([])
    let chartInstance = null;
    const dateRangeForStats = ref([null, null]);

    const selectedMetric = ref("Videos");
    const dailyStats = ref([]);


    const updateChart = () => {
      renderChart();
    };


    const renderChart = () => {
      const filteredStats = filterStatsByDate();
      const labels = filteredStats.map((item) => item.date);
      const videosData = filteredStats.map((item) => item.videos);
      const viewsData = filteredStats.map((item) => item.views);
      const earnData = filteredStats.map((item) => item.earn);

      if (chartInstance) {
        chartInstance.destroy();
      }

      const ctx = document.getElementById("dailyStatsChart").getContext("2d");

      const datasets = [];
      if (selectedMetric.value === "Videos"
      ) {
        datasets.push({
          label: "Videos",
          data: videosData,
          borderColor: '#e275ff',
          fill: false,
        });
      }
      if (selectedMetric.value === "Views" ) {
        datasets.push({
          label: "Views",
          data: viewsData,
          borderColor: "rgba(255, 206, 86, 1)",
          fill: false,
        });
      }
      if (selectedMetric.value === "Earn" ) {
        datasets.push({
          label: "Earn ($)",
          data: earnData,
          borderColor: "rgba(75, 192, 192, 1)",
          fill: false,
        });
      }

      chartInstance = new Chart(ctx, {
        type: "line",
        data: {
          labels,
          datasets,
        },
        options: {
          scales: {
            x: {
              title: { display: true, text: "Date" },
            },
            y: {
              title: { display: true, text: "Values" },
            },
          },
        },
      });
    };

    const filterStatsByDate = () => {
      const [start, end] = dateRangeForStats.value || [];
      return dailyStats.value.filter((item) => {
        const itemDate = dayjs(item.date);
        const startDate = start ? dayjs(start) : null;
        const endDate = end ? dayjs(end) : null;

        return (
            (!startDate || itemDate.isSameOrAfter(startDate)) &&
            (!endDate || itemDate.isSameOrBefore(endDate))
        );
      });
    };

    const fetchProfile = async () => {
      const p = await fetchTikTokProfilesByUsername(route.params.id)
      profile.value = p


      const q1 = await query(collection(db, 'transactions'), where('userUid', '==', p.userUid));
      const querySnapshot = await getDocs(q1);
      transactions.value = orderBy(querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        datetime_unix: doc.data().datetime,
        datetime: dayjs(doc.data().datetime * 1000).format('YYYY-MM-DD HH:mm')
      })), 'datetime_unix', ['desc']);

      tiktokProfiles.value = await fetchTikTokProfiles(p.userUid)

      console.log(tiktokProfiles.value, ' tiktokProfiles.value ')


      const q = query(collection(db, 'videos'), where('userId', '==', p.userUid));

      const balancesResponse = await getBalances(p.userUid);
      const withdrawalResponse = await getWithdrawal(p.userUid);

      try {
        stat.value.earn = parseFloat((balancesResponse.balances.usdt_trc_20).toFixed(2))
      } catch (e) {
        stat.value.earn = 0;
      }

      try {
        stat.value.withdrawal = parseFloat((withdrawalResponse.usdt_trc_20).toFixed(2))
      } catch (e) {
        stat.value.withdrawal = 0;
      }


      try {
        stat.value.currentBalance = parseFloat((stat.value.earn - stat.value.withdrawal).toFixed(2));
      } catch (e) {
        stat.value.currentBalance = 0;
      }


      const queryVideosSnapshot = await getDocs(q);
      const videosQuery = queryVideosSnapshot.docs.map(doc => ({id: doc.id, ...doc.data()})).filter((d) => d.like_count !== undefined);

      const queryTasksSnapshot = await getDocs(collection(db, "tasks"));
      const tasks = queryTasksSnapshot.docs.map(doc => ({id: doc.id, ...doc.data()}));

      videosQuery.forEach((video, key) => {
        videosQuery[key].task = tasks.find(({id}) => video.offerId === id)
        totalViews.value += Number(video.view_count || 0)
      })


      const groupedData = videosQuery.reduce((acc, video) => {
        stat.value.videos += 1
        const {offerId, view_count, like_count, share_count, comment_count} = video;
        let profileId = tiktokProfiles.value.findIndex(({id}) => id === video.profileId)

        if (profileId === -1) {
          profileId = 0
        }


        if (!acc[offerId]) {
          acc[offerId] = {
            task: video.task,
            offerId,
            share_count: 0,
            like_count: 0,
            comment_count: 0,
            view_count: 0,
            videos_count: 0,
            earn: 0,
            videos: []
          };
        }

        if (!tiktokProfiles.value[profileId].view_count) {
          tiktokProfiles.value[profileId].view_count = 0
        }

        if (!tiktokProfiles.value[profileId].like_count) {
          tiktokProfiles.value[profileId].like_count = 0
        }


        if (!tiktokProfiles.value[profileId].share_count) {
          tiktokProfiles.value[profileId].share_count = 0
        }

        if (!tiktokProfiles.value[profileId].comment_count) {
          tiktokProfiles.value[profileId].comment_count = 0
        }

        if (!tiktokProfiles.value[profileId].videos_count) {
          tiktokProfiles.value[profileId].videos_count = 0
        }

        if (!tiktokProfiles.value[profileId].earn) {
          tiktokProfiles.value[profileId].earn = 0
        }

        if (!tiktokProfiles.value[profileId].videos) {
          tiktokProfiles.value[profileId].videos = []
        }


        acc[offerId].view_count += Number(view_count || 0);
        acc[offerId].like_count += Number(like_count || 0);
        acc[offerId].share_count += Number(share_count || 0);
        acc[offerId].comment_count += Number(comment_count || 0);
        acc[offerId].videos_count += 1;

        console.log(video)

        acc[offerId].videos.push({
          profile: tiktokProfiles.value[profileId].username,
          share_url: video.share_url,
          offerId: video.offerId,
          datetime: dayjs(video.createTime * 1000).format('YYYY-MM-DD HH:mm'),
          view_count,
          like_count,
          share_count,
          comment_count,
          earn: parseFloat(((video.view_count / 1000) * (video.task.price || 0)).toFixed(3))
        })


        tiktokProfiles.value[profileId].view_count += Number(view_count || 0);
        tiktokProfiles.value[profileId].like_count += Number(like_count || 0);
        tiktokProfiles.value[profileId].share_count += Number(share_count || 0);
        tiktokProfiles.value[profileId].comment_count += Number(comment_count || 0);
        tiktokProfiles.value[profileId].videos_count += 1;
        tiktokProfiles.value[profileId].earn += (video.view_count / 1000) * (video.task.price || 0);
        tiktokProfiles.value[profileId].videos.push({
          task: video.task,
          view_count,
          share_url: video.share_url,
          datetime: dayjs(video.createTime * 1000).format('YYYY-MM-DD HH:mm'),

          like_count,
          share_count,
          comment_count,
          earn: parseFloat(((video.view_count / 1000) * (video.task.price || 0)).toFixed(3))
        })


        return acc;
      }, {});



      const groupedDataChart = videosQuery.reduce((acc, video) => {
        const date = dayjs(video.createTime * 1000).format("YYYY-MM-DD");
        if (!acc[date]) {
          acc[date] = { videos: 0, earn: 0, views: 0 };
        }
        acc[date].videos += 1;
        acc[date].views += Number(video.view_count || 0);
        acc[date].earn +=  parseFloat(((video.view_count / 1000) * (video.task.price || 0)).toFixed(2))
        return acc;
      }, {});

      dailyStats.value = sortBy(
          Object.entries(groupedDataChart).map(([date, data]) => ({
            date,
            ...data,
          })),
          ['date'] // Сортировка по дате
      );

      renderChart();


      const groupedArray = Object.values(groupedData).map((result) => {
        result.earn = parseFloat(((result.view_count / 1000) * (result.task.price || 0)).toFixed(3))

        return result
      });

      videos.value = groupedArray;

    }


    const applyFilters = () => {
      renderChart();
    };


    onMounted(fetchProfile);

    return {loading, updateChart,  applyFilters,  dateRangeForStats,  selectedMetric,
      videos, profile, totalViews, stat, tiktokProfiles, transactions};
  },
};
</script>

<style scoped lang="scss">
.preview-header {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 20px;
}

span {
  color: var(--tg-heading-font-color);
  opacity: 0.6;
  font-family: Roboto;
}

strong {
  color: var(--tg-heading-font-color);
  font-family: Roboto;
  margin-top: 10px;
}

.static-li {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 16px 8px 16px;
  border-radius: 8px;
  margin-right: 12px;
  background: rgb(21, 23, 38);
}

.statistic {
  display: flex;
  margin-top: 14px;
}

.link-color {
  color: var(--tg-heading-font-color);
  text-decoration: underline !important;
}

.description {
  margin-bottom: 8px;
}

.download-csv {
  color: var(--tg-heading-font-color);
  cursor: pointer;
}

.avatar-tiktok {
  width: 52px;
  height: 52px;
  border-radius: 6px;
}

.line-view {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.videosChart {
  max-height: 280px;
  margin-top: 16px;
}
</style>
