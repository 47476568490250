<template>
  <div style="margin-top: 22px; margin-bottom: 12px" class="daily-progress" v-if="dailyVideosCount < 5">
    <div class="header-progress">
      <img src="@/assets/coins.png" class="coins-icon"/>
      <div class="header-title">
        <h2>{{$t('progress.title')}}</h2>
        <span v-html="$t('progress.upload_more_videos_reward', { videosRemaining: 5 - dailyVideosCount })"></span>
        <div v-if="dailyVideosCount < 5" class="progress-container desktop">
          <el-progress :percentage="progress" :text-inside="true" stroke-width="14"></el-progress>
        </div>
      </div>
    </div>

    <div class="">
      <div class="block-container desktop">
        <div class="published-now">{{ dailyVideosCount }}/5</div>
        <span style="margin-top: 4px">{{$t('progress.published')}}</span>
      </div>
    </div>
    <div class="progress-container for-mobi">
      <el-progress :percentage="progress" :text-inside="true" stroke-width="14"></el-progress>
    </div>
  </div>
  <div class="header-progress progress-done" v-else style="margin-top: 22px; margin-bottom: 12px">
    <img src="@/assets/coins.png" class="coins-icon coins-complete"/>
    <div class="header-title">
      <span>{{$t('progress.finish')}}</span>
    </div>
  </div>
</template>


<script>
import {ref, onMounted} from 'vue';
import {collection, query, where, getDocs} from 'firebase/firestore';
import {db} from '@/firebase/init';
import dayjs from 'dayjs';

export default {
  name: 'DailyProgress',
  props: {
    userId: {
      type: String,
      required: true,
    }
  },
  setup(props) {
    const dailyVideosCount = ref(0);
    const progress = ref(0);

    // Функция для получения видео за текущие сутки со статусом PUBLISH_COMPLETE
    const fetchDailyVideos = async () => {
      const todayStart = dayjs().startOf('day').unix(); // Начало текущего дня в формате UNIX
      const q = query(
          collection(db, 'videos'),
          where('userId', '==', props.userId),
          where('status', '==', 'PUBLISH_COMPLETE'), // Фильтр по статусу
          where('createTime', '>=', todayStart) // Фильтр по времени загрузки за текущие сутки
      );
      const querySnapshot = await getDocs(q);
      dailyVideosCount.value = querySnapshot.size;
      // Вычисляем прогресс (сколько процентов до 5 видео)
      progress.value = Math.min((dailyVideosCount.value / 5) * 100, 100);
    };

    onMounted(async () => {
      await fetchDailyVideos();
    });

    return {
      dailyVideosCount,
      progress,
    };
  },
};
</script>

<style lang="scss" scoped>
.progress-container {
  margin-top: 24px;
  margin-bottom: 12px;
}

.reward-message {
  margin-top: 12px;
}

h6 {
  margin-bottom: 12px;
  display: flex;
}

h6, span {
  color: #fff;
}

//span {
//  background: var(--tg-primary-bg) !important;
//  padding: 18px 16px;
//  box-sizing: border-box;
//  display: flex;
//  margin-top: 12px;
//  border-radius: 12px;
//}

.daily-progress {
  background: linear-gradient(-93.17deg, var(--tg-primary-color) -18.55%, #7E2AFD 163.09%);
  border-radius: 18px;
  padding: 32px;
  display: flex;
  justify-content: space-between;
}

.coins-icon {
  width: 132px;
  height: 132px;
  margin-right: 16px;
}


.header-progress {
  display: flex;
  align-items: center;
}

h2 {
  color: #fff;
}

.header-title {
  display: flex;
  flex-direction: column;
}

.published-now {
  background: #fff;
  color: #000;
  display: flex;
  justify-content: center;
  border-radius: 18px;
  width: 76px;
  font-size: 22px;
  height: 62px;
  align-items: center;
}

.block-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.for-mobi {
  display: none;
}

@media only screen and (max-width: 600px) {
  .daily-progress {
    flex-direction: column;
    padding: 32px 22px 16px 22px;
  }

  .for-mobi {
    display: block !important;
  }

  .coins-icon {
    width: 98px;
    height: 98px;
    margin-right: 16px;
  }

  .desktop {
    display: none;
  }
}

.coins-complete {
  width: 62px;
  height: 62px;
}


.progress-done {
  background: linear-gradient(-93.17deg, var(--tg-primary-color) -18.55%, #7E2AFD 163.09%);
  padding: 16px 22px 16px 22px;
  border-radius: 18px;

  span {
    font-size: 18px;
  }
}
</style>
