<template>
  <div class="admin-page">
    <div class="create-offer-container">
      <router-link to="/admin/create-coin">
        <button class="btn-main">Add new coin</button>
      </router-link>
    </div>
    <div style="margin-top: 18px">
      <el-table class="dark-theme" :data="tasks" style="width: 100%">
        <el-table-column
            label="Preview"
            v-slot="{ row }"
        >
          <img :src="row.preview" alt="preview" style="width: 50px; height: 50px">
        </el-table-column>
        <el-table-column prop="symbol" label="Symbol"></el-table-column>
        <el-table-column prop="name" label="Name"></el-table-column>
        <el-table-column prop="currencyType" label="Currency Type"></el-table-column>

        <el-table-column fixed="right" label="Operations" width="200">
          <template #default="scope">
            <el-button
                type="primary"
                @click.prevent="editRow(scope.row.id)"
            >{{ $t('edit') }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import {onMounted, ref} from 'vue';
import {db} from '@/firebase/init';
import {collection, getDocs} from 'firebase/firestore';
import {useRouter} from "vue-router";

export default {
  setup() {
    const tasks = ref([]);
    const router = useRouter();

    const fetchTasks = async () => {
      const querySnapshot = await getDocs(collection(db, "coins"));
      tasks.value = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
    };

    onMounted(fetchTasks);


    const editRow = async (id) => {
      router.push('/admin/edit-coin/' + id)
    }

    return {tasks, editRow};
  },
};
</script>

<style lang="scss">
.admin-page {
  font-family: Roboto;

  .create-offer-container {
    display: flex;
    justify-content: flex-end;
  }

  .el-table {
    border-radius: 20px;
  }

  .el-table thead {
    color: var(--tg-heading-font-color) !important;
    height: 64px !important;
  }

  .el-table__row {
    cursor: pointer;
  }
}

.link-color {
  color: var(--tg-heading-font-color);
  text-decoration: underline !important;
}

.router-link {
  margin-right: 12px;
}
</style>
