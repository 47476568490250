<template>
  <div class="admin-page">
    <div style="margin-top: 18px">
      <el-tabs v-model="activeTab" @tab-click="handleTabClick">
        <el-tab-pane label="Pending" name="wait"></el-tab-pane>
        <el-tab-pane label="Done" name="done"></el-tab-pane>
        <el-tab-pane label="Rejected" name="reject"></el-tab-pane>
        <el-tab-pane label="All" name="all"></el-tab-pane>
      </el-tabs>

      <el-select v-model="selectedCurrency" placeholder="Select Currency" style="margin-bottom: 20px; width: 200px">
        <el-option
            v-for="currency in currencies"
            :key="currency.symbol"
            :label="currency.name"
            :value="currency.symbol">
        </el-option>
      </el-select>

      <el-table class="dark-theme" :data="paginatedTransactions" style="width: 100%">
        <el-table-column prop="status" label="Status"></el-table-column>
        <el-table-column prop="datetime" label="Datetime"></el-table-column>
        <el-table-column prop="currency" label="Currency"></el-table-column>
        <el-table-column prop="profile" label="Profile" v-slot="{ row }" width="320">
          <div>
            <div>
              Account:
              <router-link :to="`/admin/profile/${row.profile.username}`"> {{ row.profile.userUid }}</router-link>

            </div>
            <div>
              Account user view:
              <router-link :to="`/admin/profileUser/${row.profile.userUid}`">{{ row.profile.username }}</router-link>
            </div>
            <div>
              TikTok:
              <a target="_blank" :href="`https://www.tiktok.com/@${row.profile.username}`"> {{
                  row.profile.username
                }}
              </a>
            </div>
          </div>
        </el-table-column>
        <el-table-column prop="profile" label="Profile Info" v-slot="{ row }">
          <div>
            <div>
              Likes: <strong>{{ row.profile.likes_count }}</strong>
            </div>
            <div>
              Followers: <strong>{{ row.profile.follower_count }}</strong>
            </div>
            <div>
              Followings: <strong>{{ row.profile.following_count }}</strong>
            </div>
            <div>
              Video count: <strong>{{ row.profile.video_count }}</strong>
            </div>
          </div>
        </el-table-column>
        <el-table-column prop="sum" label="Amount" v-slot="{ row }">
          <div>{{ row.sum }} {{ row.currency }}</div>
        </el-table-column>
        <el-table-column prop="info" label="Info" v-slot="{ row }">
          <div style="display: flex; flex-direction: column">
            <span v-for="[key, inf] in Object.entries(row.info)" v-show="inf" :key="key">{{ key }}: <strong>{{
                inf
              }}</strong></span>
          </div>
        </el-table-column>
        <el-table-column fixed="right" label="Operations" width="200" v-slot="{ row }">
          <el-button type="primary" @click="approve(row.id)">{{ $t('approve') }}</el-button>
          <el-button type="danger" @click="reject(row.id)">{{ $t('reject') }}</el-button>
        </el-table-column>
      </el-table>

      <div class="pagination-container" style="margin-top: 20px; text-align: right;">
        <el-pagination
            v-model:currentPage="currentPage"
            :page-size="pageSize"
            :total="totalTransactions"
            layout="prev, pager, next"
            @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, computed } from 'vue';
import { collection, doc, getDocs, query, updateDoc } from "firebase/firestore";
import { db } from "@/firebase/init";
import { orderBy } from "lodash";
import dayjs from "dayjs";

export default {
  setup() {
    const transactions = ref([]);
    const currencies = ref([]); // Список валют
    const selectedCurrency = ref(''); // Выбранная валюта
    const activeTab = ref('wait');
    const currentPage = ref(1);
    const pageSize = ref(10);

    // Метод для загрузки валют
    const fetchCurrencies = async () => {
      const q = await query(collection(db, 'coins'));
      const querySnapshot = await getDocs(q);
      currencies.value = querySnapshot.docs.map(doc => ({
        symbol: doc.data().symbol,
        name: doc.data().name,
      }));
    };

    const approve = async (id) => {
      const userRef = doc(db, 'transactions', id);
      await updateDoc(userRef, {
        status: 'done'
      });
      fetchTransactions();
    };

    const reject = async (id) => {
      const userRef = doc(db, 'transactions', id);
      await updateDoc(userRef, {
        status: 'reject'
      });
      fetchTransactions();
    };

    // Метод для загрузки транзакций
    const fetchTransactions = async () => {
      const q = await query(collection(db, 'transactions'));
      const querySnapshot = await getDocs(q);
      transactions.value = orderBy(querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        datetime_unix: doc.data().datetime,
        datetime: dayjs(doc.data().datetime * 1000).format('YYYY-MM-DD HH:mm')
      })), 'datetime_unix', ['desc']);
    };

    onMounted(async () => {
      await fetchTransactions();
      await fetchCurrencies(); // Загружаем валюты при монтировании
    });

    // Фильтр транзакций по статусу и валюте
    const filteredTransactions = computed(() => {
      let filtered = transactions.value;

      if (activeTab.value !== 'all') {
        filtered = filtered.filter(transaction => transaction.status === activeTab.value);
      }

      if (selectedCurrency.value) {
        filtered = filtered.filter(transaction => transaction.currency === selectedCurrency.value);
      }

      return filtered;
    });

    const paginatedTransactions = computed(() => {
      const start = (currentPage.value - 1) * pageSize.value;
      const end = start + pageSize.value;
      return filteredTransactions.value.slice(start, end);
    });

    const totalTransactions = computed(() => filteredTransactions.value.length);

    const handlePageChange = (page) => {
      currentPage.value = page;
    };

    const handleTabClick = () => {
      currentPage.value = 1;
    };

    return {
      transactions,
      currencies, // Валюты
      selectedCurrency, // Выбранная валюта
      approve,
      reject,
      activeTab,
      currentPage,
      pageSize,
      filteredTransactions,
      paginatedTransactions,
      totalTransactions,
      handlePageChange,
      handleTabClick,
    };
  },
};
</script>


<style scoped lang="scss">
.create-offer-container {
  display: flex;
  justify-content: flex-end;
}
.pagination-container {
  display: flex;
  justify-content: flex-end;
}
</style>
