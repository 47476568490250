<template>
  <ul class="navigation">
    <li v-for="(menu, i) in filteredMenu" :key="i" :class="{ 'menu-item-has-children': menu.has_dropdown }">
      <router-link
          v-if="menu.has_dropdown"
          :to="menu.link"
          @click="closeSidebar"
          :class="[{ 'active': isActiveMenuItem(menu.link) }, `menu-${menu.title}-link`]"
      >
        {{ menu.title }}
      </router-link>
      <div
          v-if="menu.has_dropdown"
          :class="{ 'dropdown-btn': true, 'open': navTitle === menu.title }"
          @click="openMobileMenu(menu.title)"
      >
        <i :class="navTitle === menu.title ? 'fas fa-angle-up' : 'fas fa-angle-down'"></i>
      </div>
      <ul
          v-if="menu.has_dropdown && menu.sub_menus && menu.sub_menus.length > 0"
          class="sub-menu"
          :style="{ display: navTitle === menu.title ? 'block' : 'none' }"
      >
        <li v-for="(sub, index) in menu.sub_menus" :key="index">
          <router-link
              :to="sub.link"
              @click="closeSidebar"
              :class="{ 'active': isActiveSubMenuItem(sub.link) }"
          >
            {{ sub.title }}
          </router-link>
        </li>
      </ul>
      <router-link
          v-else
          :to="menu.link"
          @click="closeSidebar"
          :class="{ 'active': isActiveMenuItem(menu.link) }"
      >
        {{ menu.title }}
      </router-link>
    </li>
  </ul>
</template>

<script>
import { ref, computed } from 'vue';
import { useRoute } from 'vue-router';
import menuData from './MenuData';

export default {
  name: 'MobileMenu',
  setup() {
    const route = useRoute();
    const navTitle = ref('');

    const isActiveMenuItem = (menuLink) => {
      return route.path === menuLink;
    };

    const isActiveSubMenuItem = (subMenuLink) => {
      return route.path === subMenuLink;
    };

    const closeSidebar = () => {
      this.$emit('closeMenu')
    };

    const openMobileMenu = (menuTitle) => {
      navTitle.value = navTitle.value === menuTitle ? '' : menuTitle;
    };

    const filteredMenu = computed(() => {
      return menuData.filter((items) => items.page === 'nav_2');
    });

    return {
      navTitle,
      isActiveMenuItem,
      isActiveSubMenuItem,
      closeSidebar,
      openMobileMenu,
      filteredMenu
    };
  }
};
</script>
