<template>
  <div class="admin-page" v-loading="loading">
    <div class="statistic">
      <div class="static-li">
        <strong>{{ totalRegistrations }}</strong>
        <span>Total Registrations</span>
      </div>
      <div class="static-li">
        <strong>{{ totalTiktokConnections }}</strong>
        <span>Total TikTok Connections</span>
      </div>
      <div class="static-li">
        <strong>{{ filteredRegistrations }}</strong>
        <span>Registrations in Period</span>
      </div>
      <div class="static-li">
        <strong>{{ filteredTiktokConnections }}</strong>
        <span>TikTok Connections in Period</span>
      </div>
    </div>

    <div style="display: flex; justify-content: space-between; align-items: center;">
      <div>
        <el-date-picker v-model="startDate" type="date" placeholder="Start Date"></el-date-picker>
        <el-date-picker style="margin-left: 10px" v-model="endDate" type="date" placeholder="End Date"></el-date-picker>
        <el-button style="margin-left: 10px" type="primary" @click="updateChart">Apply Filter</el-button>
      </div>
      <div>
        <el-input
            placeholder="Search by username"
            v-model="searchQuery"
            style="width: 200px; margin-left: 20px;"
            clearable
            @input="debouncedSearchByUsername"
        ></el-input>
      </div>
    </div>

    <canvas id="videosChart" style="margin-top: 20px;"></canvas>

    <div class="pagination-container" style="margin-top: 20px; text-align: right;">
      <el-pagination
          v-model:currentPage="currentPage"
          :page-size="pageSize"
          :total="totalTasks"
          layout="prev, pager, next"
          @current-change="handlePageChange"
      ></el-pagination>
    </div>

    <div style="margin-top: 18px">
      <el-table class="dark-theme" :data="paginatedTasks" style="width: 100%" @sort-change="handleSortChange">
        <el-table-column prop="userUid" label="User uid"></el-table-column>
        <el-table-column prop="username" label="Username"></el-table-column>
        <el-table-column prop="following_count" label="Followers" sortable></el-table-column>
        <el-table-column prop="following_count" label="Followings" sortable></el-table-column>
        <el-table-column prop="likes_count" label="Likes" sortable></el-table-column>
        <el-table-column prop="video_count" label="Videos" sortable></el-table-column>
        <el-table-column fixed="right" label="Operations" width="120">
          <template #default="scope">
            <el-button link type="primary" @click.prevent="editRow(scope.row.username)">
              {{$t('view')}}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="pagination-container" style="margin-top: 20px; text-align: right;">
      <el-pagination
          v-model:currentPage="currentPage"
          :page-size="pageSize"
          :total="totalTasks"
          layout="prev, pager, next"
          @current-change="handlePageChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from 'vue';
import { db } from '@/firebase/init';
import { collection, getDocs, query, where, orderBy } from 'firebase/firestore';
import { useRouter } from 'vue-router';
import Chart from 'chart.js/auto';
import dayjs from "dayjs";
import {debounce} from "lodash";


export default {
  setup() {
    const router = useRouter();
    const tasks = ref([]);
    const currentPage = ref(1);
    const loading = ref(false);
    const pageSize = ref(30);
    const startDate = ref(dayjs().subtract(1, 'month').format('YYYY-MM-DD'));
    const endDate = ref(dayjs().format('YYYY-MM-DD'));
    const totalRegistrations = ref(0);
    const totalTiktokConnections = ref(0);
    const filteredRegistrations = ref(0);
    const filteredTiktokConnections = ref(0);
    const searchQuery = ref('');

    const analyticsData = ref([]);
    let chartInstance = null;

    const fetchTasks = async (sortField = null, sortOrder = null, searchQuery = '') => {
      loading.value = true;
      let queryRef = collection(db, "profiles");

      if (sortField && sortOrder) {
        queryRef = query(queryRef, orderBy(sortField, sortOrder));
      }

      if (searchQuery) {
        queryRef = query(queryRef, where("username", ">=", searchQuery), where("username", "<=", searchQuery + '\uf8ff'));
      }

      const querySnapshotTask = await getDocs(queryRef);
      tasks.value = querySnapshotTask.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));

      const querySnapshot = await getDocs(collection(db, 'usersAnalytics'));
      analyticsData.value = querySnapshot.docs.map(doc => ({
        date: doc.id,
        registrations: doc.data().registrations,
        tokenCreations: doc.data().tokenCreations,
      }));

      loading.value = false;
    };

    const calculateStatistics = () => {
      let totalRegs = 0;
      let totalTikToks = 0;
      let filteredRegs = 0;
      let filteredTikToks = 0;

      const filteredData = analyticsData.value.filter(item => {
        const docDate = new Date(item.date);
        const isWithinRange = (!startDate.value || docDate >= new Date(startDate.value)) &&
            (!endDate.value || docDate <= new Date(endDate.value));

        if (isWithinRange) {
          filteredRegs += item.registrations;
          filteredTikToks += item.tokenCreations;
        }
        totalRegs += item.registrations;
        totalTikToks += item.tokenCreations;
        return isWithinRange;
      });

      totalRegistrations.value = totalRegs;
      totalTiktokConnections.value = totalTikToks;
      filteredRegistrations.value = filteredRegs;
      filteredTiktokConnections.value = filteredTikToks;

      return filteredData;
    };

    const updateChart = () => {
      const filteredData = calculateStatistics();
      const labels = filteredData.map(item => item.date);
      const registrationData = filteredData.map(item => item.registrations);
      const connectTiktokData = filteredData.map(item => item.tokenCreations);

      if (chartInstance) {
        chartInstance.destroy();
      }

      const ctx = document.getElementById('videosChart').getContext('2d');
      chartInstance = new Chart(ctx, {
        type: 'line',
        data: {
          labels,
          datasets: [
            {
              label: 'Registration',
              data: registrationData,
              fill: false,
              borderColor: 'rgba(75, 192, 192, 1)',
              tension: 0.01,
            },
            {
              label: 'Connect tiktok',
              data: connectTiktokData,
              fill: false,
              borderColor: '#e275ff',
              tension: 0.1,
            },
          ],
        },
        options: {
          scales: {
            x: {
              beginAtZero: true,
            },
          },
        },
      });
    };

    const paginatedTasks = computed(() => {
      const start = (currentPage.value - 1) * pageSize.value;
      const end = start + pageSize.value;
      return tasks.value.slice(start, end);
    });

    const totalTasks = computed(() => tasks.value.length);

    const handlePageChange = (page) => {
      currentPage.value = page;
    };

    const handleSortChange = ({ prop, order }) => {
      const sortOrder = order === 'ascending' ? 'asc' : 'desc';
      fetchTasks(prop, sortOrder, searchQuery.value);
    };

    const searchByUsername = () => {
      fetchTasks(null, null, searchQuery.value);
    };

    const debouncedSearchByUsername = debounce(searchByUsername, 500);

    const editRow = async (username) => {
      router.push('/admin/profile/' + username);
    };

    onMounted(async () => {
      await fetchTasks();
      calculateStatistics();
      updateChart();
    });

    return {
      tasks,
      paginatedTasks,
      currentPage,
      pageSize,
      totalTasks,
      handlePageChange,
      editRow,
      loading,
      startDate,
      endDate,
      totalRegistrations,
      totalTiktokConnections,
      filteredRegistrations,
      filteredTiktokConnections,
      updateChart,
      handleSortChange,
      debouncedSearchByUsername,
      searchQuery,
      searchByUsername,
    };
  },
};
</script>

<style scoped lang="scss">
.create-offer-container {
  display: flex;
  justify-content: flex-end;
}
.pagination-container {
  display: flex;
  justify-content: flex-end;
}

#videosChart {
  max-height: 200px;
  margin-top: 16px;
}

.static-li {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 16px 8px 16px;
  border-radius: 8px;
  margin-right: 12px;
  background: rgb(21, 23, 38);
}

.statistic {
  display: flex;
  margin-top: 14px;
  margin-bottom: 16px;
}
span {
  color: var(--tg-heading-font-color);
  opacity: 0.6;
  font-family: Roboto;
}

strong {
  color: var(--tg-heading-font-color);
  font-family: Roboto;
  margin-top: 10px;
}
</style>
