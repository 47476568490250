<template>
  <div class="current-offer">
    <div class="offer-container">
      <img class="offer-preview" v-if="task" :src="task.preview" />
    </div>
    <div class="offer-info" v-if="task">
      <div class="offer-title">
        <h2 class="title">{{ localizedTitle }}</h2>
      </div>

      <span class="offer-description" v-html="localizedDescription"></span>

      <div class="offer-participants mt-2">
        <!-- Additional content if needed -->
      </div>

      <div class="offer-participants" style="border-bottom: none">
        <span class="offer-label">{{ $t('pay_per_1000_views') }}</span>
        <div class="participants-count">
          <img
              v-if="!task?.coin"
              class="icon-info"
              src="@/assets/usdt.svg"
          />
          <img
              v-else
              :class="['icon-info', task?.coin?.symbol]"
              style="width: 24px"
              :src="task?.coin?.preview"
          />

          <strong>{{ task.price || '-' }} {{ task?.coin?.name || 'POINT' }}</strong>
        </div>
      </div>

      <div class="manual">
        <PulseDot style="margin-top: -27px;" />
        <span style="margin-left: 42px">
          {{ $t('current_offer.choose_video_title') }}
        </span>
      </div>

      <h3 class="choose-video-title">{{ $t('choose_video_creative') }}</h3>

      <div class="choose-creatives" v-if="task && task.videos">
        <CreativeCard
            v-for="video in task.videos"
            :key="video.id"
            :src_preview="video.preview_href"
            :lock="video.lock"
            :src="video.href"
            :id="task.id"
            :videoId="video.id"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CreativeCard from '@/components/CreativeCard';
import PulseDot from '@/components/PulseDot';
import { onMounted, computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import { db } from '@/firebase/init';
import { doc, getDoc } from 'firebase/firestore';
import { shuffle } from 'lodash';
import { useI18n } from 'vue-i18n';

export default {
  name: 'CurrentOfferPage',
  components: { PulseDot, CreativeCard },
  setup() {
    const task = ref(null);
    const route = useRoute();
    const { locale } = useI18n();

    const fetchTask = async () => {
      const taskId = route.params.id;
      const docRef = doc(db, 'tasks', taskId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const taskDoc = { id: docSnap.id, ...docSnap.data() };
        task.value = {
          ...taskDoc,
          videos: shuffle(taskDoc.videos),
        };
      } else {
        console.log('Task not found!');
      }
    };

    onMounted(async () => {
      await fetchTask();
    });

    const localizedTitle = computed(() => {
      if (!task.value) return '';
      if (locale.value === 'es' && task.value.titleEs) {
        return task.value.titleEs;
      } else if (locale.value === 'pt' && task.value.titlePt) {
        return task.value.titlePt;
      } else {
        return task.value.title || '';
      }
    });

    const localizedDescription = computed(() => {
      if (!task.value) return '';
      let descriptionText = '';
      if (locale.value === 'es' && task.value.descriptionEs) {
        descriptionText = task.value.descriptionEs;
      } else if (locale.value === 'pt' && task.value.descriptionPt) {
        descriptionText = task.value.descriptionPt;
      } else {
        descriptionText = task.value.description || '';
      }

      const urlPattern = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig; // eslint-disable-line

      return descriptionText.replace(
          urlPattern,
          '<a class="link-description" href="$1" target="_blank">$1</a>'
      );
    });

    return { task, localizedTitle, localizedDescription };
  },
};
</script>
<style lang="scss">
.link-description {
  color: #4F61FF !important;
  text-decoration: underline !important;
  margin-top: 4px;
}
</style>
<style scoped lang="scss">
.current-offer {
  .offer-preview {
    width: 100%;
    height: auto;
    max-height: 336px;
    object-fit: cover;
    border-radius: 20px;
    max-width: 1024px;
    margin: 16px auto 32px auto;
  }


  .offer-info {
    max-width: 1024px;
    flex-direction: column;
    margin: 0 auto;

    h2 {
      font-size: 32px;

      &:before {
        margin-left: calc(60% + 14px);
        height: 7px;
        width: 40%;

        margin-top: 26px;
      }
    }
  }

  .offer-title {
    display: flex;
    justify-content: flex-start;
  }

  .offer-description {
    margin-top: 20px;
    display: inline;
    padding-bottom: 20px;

    white-space: pre-wrap;
  }

  .offer-description, .offer-label {
    display: inline;
    font-size: 17px;
    color: var(--tg-second-color);
    font-weight: 300;
    font-family: Roboto;
  }

  .offer-participants {
    max-width: 420px;
    padding: 12px 0;
    border-bottom: 1px solid var(--tg-second-bg);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .icon-info {
    margin-right: 8px;
  }

  .participants-count {
    color: var(--tg-heading-font-color);
    font-weight: 600;
    align-items: center;
    display: flex;
    font-size: 15px;
    font-family: Roboto;
  }

  .icon-info.hot {
    width: 24px;
    height: 24px;
    margin-right: 4px;
  }

  .choose-video-title {
    margin-top: 32px;
    margin-bottom: 18px;
  }
}

.offer-container {
  display: flex;
  justify-content: center;
}

.choose-creatives {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 16px 16px;
  padding-bottom: 100px;
}

.mt-2 {
  margin-top: 20px;
}

@media only screen and (max-width: 600px) {
  .offer-info {
    padding: 0 8px;
  }

  .offer-preview {
    max-width: 1024px;
    border-radius: 0 !important;
    margin-top: 0 !important;
  }

  .current-offer .offer-participants {
    max-width: 100%;
  }

  .choose-creatives {
    grid-template-columns: 1fr 1fr;
    gap: 8px 8px;
  }
}

.manual {
  display: flex;
  align-items: center;
  padding: 32px 16px;
  border-radius: 12px;
  color: var(--tg-heading-font-color);
  background: var(--tg-primary-bg)
}
</style>
