<template>
  <div class="login-page center-container">
    <h2 class="title">{{ $t('sing_in_streamsale') }}</h2>
    <div class="main-form login-page-form">
      <div class="google-login" @click="loginWithGoogle">
        <img src="@/assets/google.svg"/>
        <span>{{ $t('btn_google') }}</span>
      </div>
      <div class="or-line">
        <div class="line-border"></div>
        <div class="line-text">{{ $t('or') }}</div>
      </div>

      <div class="group-input">
        <label class="main-label">{{ $t('email') }}</label>
        <input v-model="email" type="email" class="main-input"/>
      </div>
      <div class="group-input">
        <label class="main-label">{{ $t('password') }}</label>
        <input v-model="password" type="password" class="main-input"/>
      </div>
      <button data-tag data-cat="auth" data-label="login" data-value="login-page"
              class="btn-solid login-button login-button-click" @click="login">{{ $t('login') }}
      </button>

      <div class="footer-login">
        <div class="reset-pass">
          <router-link class="link-blue" to="/reset">{{ $t('reset_password') }}</router-link>
        </div>

        <div>
          <span class="no-acc">{{ $t('no_acc') }} <router-link class="link-blue"
                                                to="/registration">{{ $t('create_acc') }}</router-link></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {auth} from '@/firebase/init';
import {getAuth, signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup} from 'firebase/auth';
import {ref} from 'vue';
import {useRouter} from "vue-router";
import {fetchTikTokProfiles} from "@/firebase/queries";
import {trackEvent} from "@/plugins/analytics";
import {ElNotification} from "element-plus";

export default {
  name: "LoginIndex",
  setup() {
    const email = ref('');
    const password = ref('');
    const router = useRouter();
    const provider = new GoogleAuthProvider();


    const trackRegEvent = () => {
      trackEvent('registration_button', 'navigation', 'login_page');
    };

    const login = () => {
      signInWithEmailAndPassword(auth, email.value, password.value)
          .then(async () => {
            const auth = getAuth();
            const user = auth.currentUser;
            const userUid = user.uid;
            const prof = await fetchTikTokProfiles(userUid);

            if (prof && prof.length) {
              router.push('/offers');
            } else {
              router.push('/connect');
            }
          })
          .catch(error => {
            let errorMessage = 'An unexpected error occurred.';
            switch (error.code) {
              case 'auth/invalid-email':
                errorMessage = 'The email address is not valid.';
                break;
              case 'auth/user-disabled':
                errorMessage = 'This user has been disabled.';
                break;
              case 'auth/user-not-found':
                errorMessage = 'User not found.';
                break;
              case 'auth/wrong-password':
                errorMessage = 'Incorrect password.';
                break;
              default:
                errorMessage = error.message;
            }

            ElNotification({
              title: 'Error!',
              message: errorMessage,
              type: 'error',
            });
          });
    };

    const loginWithGoogle = () => {
      signInWithPopup(auth, provider)
          .then(async (result) => {
            const user = result.user;
            const userUid = user.uid;
            const prof = await fetchTikTokProfiles(userUid);

            if (prof && prof.length) {
              router.push('/offers');
            } else {
              router.push('/connect');
            }
          })
    };

    return {email, password, login, loginWithGoogle, trackRegEvent};
  }
};
</script>

<style scoped lang="scss">
.login-page {
  margin-top: 80px;
  margin-bottom: 80px;
  padding: 8px;
}

.login-page-form {
  max-width: 382px;
  width: calc(100% - 16px);

  .group-input {
    width: 100%;
    margin-top: 32px;
  }
}

.login-button, .login-google-button {
  margin-top: 24px;
}

.registration-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  margin-top: 32px;
}

.login-reg-button {
  height: 30px;
}

.google-login {
  font-size: 18px;
  height: 58px;
  background: rgba(255, 255, 255, 0.15);
  color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.4s ease;
  cursor: pointer;
  border-radius: 22px;
  padding: 0 16px;
  text-decoration: none !important;
  border: none;
  margin-top: 24px;
  margin-bottom: 24px;

  img {
    margin-right: 8px;
  }

}

.or-line {
  display: flex;
  justify-content: center;
  flex-direction: column-reverse;
  align-items: center;

  .line-border {
    background: rgba(255, 255, 255, 0.15);
    height: 1px;
    width: 100%;
  }

  .line-text {
    width: 52px;
    height: 24px;
    background: #0b0b0b;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    position: relative;
    margin-bottom: -13px;
    font-size: 18px;
    color: #92939E;
  }
}

.footer-login {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;
}

.link-blue {
  color: #8D98FF;
  font-size: 18px;
  font-weight: bold;
}

.no-acc {
  color: #92939E;
  font-weight: bold;
}

.reset-pass {
  margin-bottom: 22px;
}
</style>
