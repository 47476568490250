<template>
  <div class="task-view-page" v-loading="loading">
    <div class="users-stat" v-if="taskView">
      <img :src="taskView.preview" class="preview-header"/>
      <h3 style="margin-top: 30px">{{ taskView.title }}</h3>
      <div class="description">
        <span>
          {{ taskView.description || '-' }}
        </span>
      </div>
      <div class="statistic">
        <div class="static-li">
          <strong> {{ statistic.view_count || '-' }}</strong>
          <span>{{ $t('total_views') }}</span>
        </div>
        <div class="static-li">
          <strong>{{ statistic.like_count || '-' }}</strong>
          <span>{{ $t('total_likes') }}</span>

        </div>
        <div class="static-li">
          <strong>{{ statistic.comment_count || '-' }}</strong>
          <span>{{ $t('total_comment') }}</span>

        </div>
        <div class="static-li">
          <strong>{{ statistic.share_count || '-' }}</strong>
          <span>{{ $t('total_shares') }}</span>

        </div>
        <div class="static-li">
          <strong>{{ statistic.publish_count || '-' }}</strong>
          <span>{{ $t('total_videos_publish') }}</span>

        </div>
        <div class="static-li">
          <strong>{{ statistic.unique_users_count || '-' }}</strong>
          <span>{{ $t('users') }}</span>
        </div>
      </div>

      <canvas id="videosChart"></canvas>
      <div class="line-view" style="margin-top: 22px">
        <h4>{{ $t('users') }}</h4>
        <el-button type="primary" class="download-csv" @click="downloadCSV">
          <el-icon style="margin-right: 8px">
            <Download/>
          </el-icon>
          {{ $t('_csv') }}
        </el-button>
      </div>
      <el-table
          :default-sort="{ prop: 'view_count', order: 'descending' }"
          class="dark-theme" :data="profiles" style="width: 100%">
        <el-table-column type="expand">
          <template #default="props">
            <div class="table-inner">
              <el-table :data="props.row.videos">
                <el-table-column label="Link" prop="share_url" v-slot="{ row }">
                  <a class="link-color" target="_blank" :href="`${row.share_url}`">{{ row.share_url }}</a>
                  <span v-if="row.is_scam" class="scam-alert"> SCAM ALERT </span>
                </el-table-column>
                <el-table-column width="120px" label="Views" prop="view_count"/>
                <el-table-column width="120px" label="Likes" prop="like_count"/>
                <el-table-column width="120px" label="Comments" prop="comment_count"/>
                <el-table-column width="120px" label="Shares" prop="share_count"/>
                <el-table-column width="120px" label="Earn" prop="earn"/>
              </el-table>
            </div>
          </template>
        </el-table-column>
        <el-table-column
            prop="userData.url"
            label="Profile"
            v-slot="{ row }">
          <router-link :to="`/admin/profile/${row.userData.username}`" v-if="row.userData">
            {{ row.userData.username }}
          </router-link>
        </el-table-column>
        <el-table-column
            prop="userData.url"
            label="TikTok url"
            v-slot="{ row }">
          <a class="link-color" target="_blank"
             :href="`${row.userData ? row.userData.url : ''}`">{{ row.userData ? row.userData.url : '' }}</a>
        </el-table-column>
        <el-table-column prop="view_count" sortable label="Views"></el-table-column>
        <el-table-column prop="like_count" sortable label="Likes"></el-table-column>
        <el-table-column prop="comment_count" sortable label="Comments"></el-table-column>
        <el-table-column prop="share_count" sortable label="Shares"></el-table-column>
        <el-table-column prop="total_videos" sortable label="Videos"></el-table-column>
        <el-table-column prop="earn" sortable label="Earn"></el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import {onMounted, ref} from 'vue';
import {db} from '@/firebase/init';
import {collection, doc, getDoc, getDocs, query, where} from 'firebase/firestore';
import {useRoute} from "vue-router";
import Papa from 'papaparse';
import Chart from 'chart.js/auto';

function extractUsername(url) {
  const pattern = /@([^/]+)\/video\//;
  const match = url.match(pattern);
  if (match) {
    return match[1];
  } else {
    return null;
  }
}

export default {
  setup() {
    const taskView = ref({});
    const statistic = ref({});
    const profiles = ref([]);
    const loading = ref(true);

    const tableData = [];
    const videos = ref([]);
    const route = useRoute();

    const taskId = route.params.id;

    const downloadCSV = () => {
      console.log(tableData, 'tableData')
      const csv = Papa.unparse(tableData);
      const blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'report.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    };

    const fetchTasks = async () => {
      const docRef = doc(db, "tasks", taskId);
      const docSnap = await getDoc(docRef);
      const task = docSnap.data();
      taskView.value = task;

      const videosQuery = query(
          collection(db, "videos"),
          where('offerId', '==', taskId),
          where('is_scam', '!=', true),

      );

      const queryVideosSnapshot = await getDocs(videosQuery);

      let view_count = 0;
      let like_count = 0;
      let comment_count = 0;
      let share_count = 0;
      let publish_count = 0;
      let users = new Set();
      let usersStat = {};

      let result = [];
      const userIdToShareUrl = {};

      queryVideosSnapshot.docs.forEach(dVideo => {
        const data = dVideo.data();
        if (data.status === "PUBLISH_COMPLETE" && data.share_url) {
          users.add(data.userId);
          userIdToShareUrl[data.userId] = data.share_url;
        }
      });

      const profilesColRef = collection(db, "profiles");

      const getUserData = async (userId) => {
        let userData = {};
        let username = extractUsername(userIdToShareUrl[userId]);
        let querySnapshot = await getDocs(query(profilesColRef, where("username", "==", username)));

        if (querySnapshot.docs.length === 0) {
          querySnapshot = await getDocs(query(profilesColRef, where("userUid", "==", userId)));
        }

        if (querySnapshot.docs.length > 0) {
          userData = querySnapshot.docs[0].data();
          userData.url = `https://tiktok.com/@${username}`;
        } else {
          userData.url = `https://tiktok.com/@${username}`;
        }

        usersStat[userId] = {
          userData,
          videos: [],
          view_count: 0,
          like_count: 0,
          comment_count: 0,
          share_count: 0,
          earn: 0,
          total_videos: 0,
        };
      };

      await Promise.all([...users].map(userId => getUserData(userId)));

      let videosByDate = {};
      let scamVideosByDate = {};
      let notUploadVideosByDate = {};


      queryVideosSnapshot.docs.forEach(dVideo => {
        const data = dVideo.data();
        if (data.status === "PUBLISH_COMPLETE" && data.share_url) {
          const userId = data.userId;
          const date = new Date(data.createTime * 1000).toISOString().split('T')[0];

          if (!videosByDate[date]) {
            videosByDate[date] = 0;
            scamVideosByDate[date] = 0;
          }

          if(!notUploadVideosByDate[date]) {
            notUploadVideosByDate[date] = 0;
          }

          if (!data.is_scam) {
            videosByDate[date]++;
          } else {
            scamVideosByDate[date]++;
          }

          usersStat[userId].view_count += Number(data.view_count || 0);
          usersStat[userId].like_count += Number(data.like_count || 0);
          usersStat[userId].comment_count += Number(data.comment_count || 0);
          usersStat[userId].share_count += Number(data.share_count || 0);
          usersStat[userId].total_videos += 1;

          usersStat[userId].videos.push({
            ...data,
            earn: parseFloat(Number((data.view_count) * (task.price / 1000)).toFixed(6))
          });

          view_count += data.view_count || 0;
          like_count += data.like_count || 0;
          comment_count += data.comment_count || 0;
          share_count += data.share_count || 0;
          publish_count += 1;
        } else {
          const date = new Date(data.createTime * 1000).toISOString().split('T')[0];

          console.log(notUploadVideosByDate, 'notUploadVideosByDate notUploadVideosByDate notUploadVideosByDate')

          if (!notUploadVideosByDate[date]) {
            notUploadVideosByDate[date] = 0;
          }
          notUploadVideosByDate[date] += 1;
        }
      });

      statistic.value = {
        view_count,
        like_count,
        comment_count,
        share_count,
        publish_count,
        unique_users_count: users.size
      };

      videos.value = result;
      profiles.value = Object.values(usersStat).map((r) => {
        tableData.push({
          username: r.userData.username,
          tiktok: r.userData.url,
          view_count: r.view_count,
          like_count: r.like_count,
          comment_count: r.comment_count,
          share_count: r.share_count,
          is_scam: r.is_scam,
          earn: parseFloat(Number((r.view_count) * (task.price / 1000)).toFixed(6))
        });
        return {
          ...r,
          earn: parseFloat(Number((r.view_count) * (task.price / 1000)).toFixed(6)),
        };
      });

      const labels = Object.keys(videosByDate).sort();
      const data = labels.map(date => videosByDate[date]);


      const labelsScam = Object.keys(scamVideosByDate).sort();
      const dataScam = labelsScam.map(date => scamVideosByDate[date]);

      console.log(notUploadVideosByDate, 'notUploadVideosByDate')

      const labelsNotUpload = Object.keys(notUploadVideosByDate).sort();
      const dataNotUpload = labelsNotUpload.map(date => notUploadVideosByDate[date]);

      const ctx = document.getElementById('videosChart').getContext('2d');
      new Chart(ctx, {
        type: 'line',
        data: {
          labels,
          datasets: [{
            label: 'Number of Videos',
            data,
            fill: false,
            borderColor: 'rgba(75, 192, 192, 1)',
            tension: 0.01
          }, {
            label: 'Not published',
            data: dataNotUpload,
            fill: false,
            borderColor: 'rgb(255, 165, 0)',
            tension: 0.1
          },
            {
              label: 'Scam videos',
              data: dataScam,
              fill: false,
              borderColor: 'rgb(255,69,0)',
              tension: 0.1
            }]
        },
        options: {
          scales: {
            x: {
              beginAtZero: true
            }
          }
        }
      });

      loading.value = false;
    };

    onMounted(fetchTasks);

    return {taskView, profiles, statistic, loading, downloadCSV};
  },
};
</script>


<style scoped lang="scss">
.preview-header {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 20px;
}

span {
  color: var(--tg-heading-font-color);
  opacity: 0.6;
  font-family: Roboto;
}

strong {
  color: var(--tg-heading-font-color);
  font-family: Roboto;
  margin-top: 10px;
}

.static-li {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 16px 8px 16px;
  border-radius: 8px;
  margin-right: 12px;
  background: rgb(21, 23, 38);
}

.statistic {
  display: flex;
  margin-top: 14px;
}

.link-color {
  color: var(--tg-heading-font-color);
  text-decoration: underline !important;
}

.description {
  margin-bottom: 8px;
}

.download-csv {
  color: var(--tg-heading-font-color);
  margin-bottom: 8px;
  cursor: pointer;
}

.line-view {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.scam-alert {
  background: red;
  color: var(--tg-heading-font-color) !important;
  padding: 2px 4px;
  border-radius: 4px;
  margin-left: 8px;
  opacity: 1;
  font-size: 12px;
}

#videosChart {
  max-height: 200px;
  margin-top: 16px;
}
</style>
