<template>
  <div class="manual-page">
    <h1>{{ $t('manual.title') }}</h1>

    <div
        v-for="(step, index) in $i18n.messages[$i18n.locale].manual.steps"

        :key="index"
        class="step-section"
    >
      <h2 class="step-flow" v-if="step.stepNumber">{{ step.stepNumber }}</h2>
      <p>{{ step.description }}</p>
      <div class="step-images">
      <div v-for="(image, idx) in step.images" :key="idx">
        <img :src="image" alt="Step Image" />
      </div>
      </div>
    </div>

    <div class="contact-section">
      <h3>{{ $t('manual.contact.title') }}</h3>
      <p v-html="$t('manual.contact.content')"></p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ManualPage',
};
</script>

<style scoped lang="scss">
.manual-page {
  background-color: #ffffff;
  color: #000000;
  max-width: 1020px;
  margin: 20px auto;
  border-radius: 22px;
  padding: 20px;
  font-family: Arial, sans-serif;

  img {
    max-height: 420px;
    border-radius: 12px;
  }
}

h1,
h2,
h3 {
  color: #000000;
}

.step-section {
  margin-bottom: 40px;
}

.step-flow {
  display: flex;
  justify-content: flex-start;
  font-size: 24px;
  background: #f6f6f6;
  border-radius: 8px;
  padding: 12px 16px;
}

.step-section img {
  max-width: 100%;
  height: auto;
  margin-top: 10px;
}

.contact-section {
  margin-top: 20px;
}

p {
  color: #000000;
  white-space: pre-wrap;
  font-size: 18px;
}

.step-images {
  display: flex;
  flex-wrap: wrap;

  img {
    border-radius: 12px;
    margin-right: 12px;
  }
}
</style>
