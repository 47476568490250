<template>
  <el-container style="min-height: 100vh;" v-loading="loader">
    <el-aside width="160px" style="padding-left: 22px; padding-top: 22px">
      <el-menu>
        <el-menu-item index="1">
          <router-link to="/admin">{{$t('tasks')}}</router-link>
        </el-menu-item>
        <el-menu-item index="2">
          <router-link to="/admin/transactions">{{$t('transactions')}}</router-link>
        </el-menu-item>
        <el-menu-item index="3">
          <router-link to="/admin/profiles">{{$t('profiles')}}</router-link>
        </el-menu-item>
        <el-menu-item index="4">
          <router-link to="/admin/coins">{{$t('Coins')}}</router-link>
        </el-menu-item>

        <el-menu-item index="4">
          <router-link to="/admin/stat">{{$t('Video stat')}}</router-link>
        </el-menu-item>
      </el-menu>
    </el-aside>

    <el-container>
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import {onMounted, ref} from "vue";
import {getAuth} from "firebase/auth";
import {collection, getDocs, getFirestore, query, where} from "firebase/firestore";
import {useRouter} from "vue-router";
const db = getFirestore();

export default {
  name: "AdminPage",
  setup() {
    const router = useRouter()
    const loader = ref(true);

    document.body.classList.remove('white-theme');


    onMounted(async () => {
      const auth = getAuth();
      const user = auth.currentUser;
      const userUid = user.uid;
      const profilesColRef = collection(db, "admin");
      const q = query(profilesColRef, where("userUid", "==", userUid));
      const querySnapshot = await getDocs(q);
      if (querySnapshot.docs && querySnapshot.docs.length ) {
        loader.value = false;

      } else {
        router.push('/')
      }

    });

    return {
      loader
    }
  }
}
</script>

<style scoped>

</style>
