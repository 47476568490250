<template>
  <div class="pulse-dot">
  <div class="pulsating-circle"></div>
  </div>
</template>

<script>
export default {
  name: "PulseDot"
}
</script>

<style scoped lang="scss">
.pulse-dot {
  display: flex;
  justify-content: center;
  align-items: center;
}
.pulsating-circle {
  transform: translateX(-50%) translateY(-50%);
  width: 30px;
  height: 30px;

  &:before {
    content: '';
    position: absolute;
    display: block;
    width: 300%;
    height: 300%;
    box-sizing: border-box;
    border-radius: 45px;
    background-color: #E275FF;
    animation: pulse-ring 2.2s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  }

  &:after {
    content: '';
    left: 0;
    top: 0;
    margin-left: 100%;
    position: absolute;
    margin-top: 100%;
    display: block;
    width: 100%;
    height: 100%;
    background-color: #E275FF;
    border-radius: 15px;
    box-shadow: 0 0 8px rgba(0,0,0,.3);
    animation: pulse-dot 2.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite;
  }
}

@keyframes pulse-ring {
  0% {
    transform: scale(.33);
  }
  80%, 100% {
    opacity: 0;
  }
}

@keyframes pulse-dot {
  0% {
    transform: scale(.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(.8);
  }
}
</style>
