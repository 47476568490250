<template>
  <div class="app">
    <HeaderMain/>
    <router-view></router-view>

    <FooterMain/>
    <div class="mobile-menu-bottom" v-if="userEmail">
      <router-link to="/settings" class="mobile-menu-li menu-settings-link">
        <el-icon style="font-size: 22px">
          <Setting/>
        </el-icon>
        <span>{{$t('settings')}}</span>
      </router-link>
      <router-link to="/referral" class="mobile-menu-li menu-referral-link">
        <el-icon style="font-size: 22px">
          <Connection/>
        </el-icon>
        <span>{{$t('referral')}}</span></router-link>
      <router-link to="/" class="mobile-menu-li menu-offers-link">
        <div class="offers-menu">
        <el-icon style="font-size: 22px; color: #fff !important;">
          <Money/>
        </el-icon>
        <span style="color: #fff !important;">{{$t('offers')}}</span>
        </div>
      </router-link>

      <router-link to="/wallet" class="mobile-menu-li menu-wallet-link">
        <el-icon style="font-size: 22px">
          <Wallet/>
        </el-icon>
        <span>{{$t('wallet')}}</span></router-link>
      <router-link to="/profile" class="mobile-menu-li menu-profile-link">
        <el-icon style="font-size: 22px">
          <User/>
        </el-icon>
        <span>{{$t('profile')}}</span></router-link>
    </div>

    <div v-else-if="route.fullPath !== '/login'" class="login-button-fixed">

      <router-link
          data-tag data-cat="navigate" data-label="register" data-value="header"
          style="height: 56px; font-size: 18px !important; padding: 0 18px" class="btn" v-if="!userEmail" to="/login">
        {{$t('sign-in')}}
      </router-link>
    </div>
  </div>
</template>

<script>

import HeaderMain from "@/components/HeaderMain";
import FooterMain from "@/components/FooterMain";
import {onMounted, ref} from "vue";
import {getAuth, onAuthStateChanged} from "firebase/auth";
import {useRoute} from "vue-router";

export default {
  name: 'App',
  components: {FooterMain, HeaderMain},
  setup() {

    const route = useRoute()
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.has('ref')) {
      const refValue = urlParams.get('ref');
      localStorage.setItem('ref', refValue);
    }


    const isActive = ref(false);

    const setIsActive = (value) => {
      isActive.value = value;
    };

    const auth = getAuth();
    const userEmail = ref('');


    onMounted(() => {
      onAuthStateChanged(auth, (authUser) => {
        if (authUser) {
          userEmail.value = authUser.email;
        }
      });
    });


    return {
      isActive,
      userEmail,
      route,
      setIsActive
    };
  }
}
</script>

<style lang="scss">
.dark-theme {
  --el-table-row-hover-bg-color: rgb(21, 23, 38) !important;
  --el-table-bg-color: rgb(21, 23, 38) !important;
  --el-box-shadow-light: rgb(21, 23, 38) !important;
  --el-table-border-color: rgb(21, 23, 38) !important;
  --el-table-expanded-cell-bg-color: rgb(21, 23, 38) !important;
  --el-table-border: rgb(21, 23, 38) !important;
  --el-table-border-color: rgb(21, 23, 38) !important;
  --el-pagination-button-disabled-bg-color: rgb(21, 23, 38) !important;
}

:root {
  --el-fill-color-light: #f8f8f8 !important;
  --el-color-primary: #E275FF !important;
  --el-table-expanded-cell-bg-color: var(--tg-second-dark) !important;
  --el-table-border: #777 !important;
  --el-table-border-color: #777 !important;
  --el-pagination-bg-color: var(--tg-second-dark) !important;
  --tg-primary-bg: rgba(255, 255, 255, 0.08);
  --tg-second-bg: hsla(0,0%,100%,.15);
  --tg-second-color: #eee !important;
  --tg-second-dark: #222 !important;
}



.logo-black {
  display: none !important;
}

.white-theme {
   --tg-black: #f6f6f6 !important;
   --tg-heading-font-color: #060606 !important;
  --tg-primary-bg:  #fff  !important;
  --tg-second-bg: rgba(0, 0, 0, 0.05) !important;
  --tg-paragraph-color: #000 !important;
  --tg-second-color: #444 !important;
  --tg-second-dark: #eee !important;

  .lang-switcher-menu-dark {
    .el-select__wrapper {
      background: hsla(0, 0%, 100%, .15) !important;
    }

    .el-select__selected-item  span{
      color: #fff !important;
    }
  }

  .mobile-menu .navigation li > a {
    color: #fff !important;
  }

  .mobile-menu-bottom {
    background: #fff !important;
  }

  .Reais, .reais{
    background: green !important;
    padding: 0 4px;
    box-sizing: content-box;
    border-radius: 4px;
  }

  .login-reg-button {
    background: #000 !important;
  }

  .upload-edit {
    background: var(--tg-second-bg) !important;
    color: #000 !important;
    &:hover {
      background: var(--tg-primary-color) !important;
      color: var(--tg-white) !important;
      border-color: var(--tg-primary-color) !important;
    }
  }
  .main-input   {
    .el-select__wrapper {
      background: rgba(0,0,0,0) !important;
    }

  }

  .logo-white {
    display: none  !important;;
  }

  .logo-black {
    display: block !important;
  }

  .el-loading-mask {
    background: rgba(255, 255, 255, 0.3) !important;
  }

  .footer-wrapper .social-btn a {
    color: #000 !important;
  }

  .copyright-text, .copyright-text a {
    color: #000 !important;
  }

  .widget-contact .contact-info-link a {
    color: #000 !important;
  }
}


.el-select {
  --el-color-primary: black !important;
}

.el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf {
  border-color: var(--tg-second-bg) !important;
}

.el-table--border .el-table__inner-wrapper:after, .el-table--border:after, .el-table--border:before, .el-table__inner-wrapper:before {
  background-color: var(--tg-second-bg) !important;
  content: "";
  position: absolute;
  z-index: calc(var(--el-table-index) + 2);
}

.el-loading-mask {
  background: rgba(0, 0, 0, 0.3) !important;

  .el-loading-spinner .path {
    stroke-width: 8px !important;
  }
}

.table-inner {
  padding: 0 42px !important;
  box-sizing: border-box;

  .el-table {
    border-radius: 2px !important;
  }

  .cell {
    color: var(--tg-heading-font-color) !important;
  }
}

.el-switch__label [aria-hidden="true"] {
  color: var(--tg-heading-font-color) !important;
}

.link-description {
  color: #4F61FF !important;
  text-decoration: underline !important;
  margin-top: 4px;
}

.el-upload-list__item {
  background: var(--tg-primary-bg) !important;
  border: 1px solid var(--tg-second-bg) !important;

  .el-upload-list__item-thumbnail {
    background: #000 !important;
    border-radius: 6px !important;
  }
}

.el-message-box {
  border-radius: 50px !important;
  overflow: hidden;
  padding: 20px 34px !important;
}

.el-message-box__message p {
  color: #111111 !important;
  --tg-paragraph-color: #111 !important;
}

.el-message-box__headerbtn {
  margin-top: 20px;
  font-size: 24px !important;
  margin-right: 20px;
}

.el-message-box__close {
  font-size: 24px;
}

.el-main {
  overflow: unset !important;
}

.el-checkbox__label {
  color: var(--tg-heading-font-color) !important;
  font-size: 18px !important;
}

.el-pagination button {
  margin: 0 4px !important;
  background: rgb(21, 23, 38) !important;

}

.el-pager li {
  margin: 0 2px !important;
  background: rgb(21, 23, 38) !important;
  color: var(--tg-heading-font-color) !important;

  &.is-active {
    color: #E275FF !important;
  }
}

.el-tabs__item {
  color: var(--tg-heading-font-color) !important;
  cursor: pointer;

  &.is-active {
    color: #E275FF !important;

  }
}

.el-tabs__nav-wrap:after {
  background-color: var(--tg-second-bg) !important;
}

.el-select__wrapper {
  box-shadow: none !important;
}

.el-select__wrapper {
  background: var(--tg-second-bg) !important;
  border-radius: 20px !important;
  height: 48px !important;
  font-size: 16px !important;

  .el-select__selected-item {
    span {
      color: var(--tg-heading-font-color) !important;
    }
  }
}

.mobile-menu-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 82px;
  background: #000;
  display: none;
  color: var(--tg-heading-font-color);
  font-size: 14px;
  justify-content: space-between;
  padding: 0 12px;
  align-items: center;
  z-index: 9999;
}

.mobile-menu-li {
  display: flex;
  flex-direction: column;
  color: var(--tg-heading-font-color);
  align-items: center;
  justify-content: center;

  span {
    margin-top: 4px;
    font-size: 14px;
    color: var(--tg-heading-font-color);
  }
}

@media only screen and (max-width: 600px) {
  .mobile-menu-bottom {
    display: grid;
    flex: 1;
    grid-template-columns: repeat(5, 1fr);
  }
}

.offers-menu {
  width: 66px;
  height: 66px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 50%;
  background: linear-gradient(93.17deg, var(--tg-primary-color) -18.55%, #7E2AFD 163.09%);
}


.el-collapse-item__wrap, .el-collapse-item__header {
  background: rgba(0, 0, 0, 0) !important;
}

.el-collapse, .el-collapse-item, .el-collapse-item__header, .el-collapse-item__content, .el-collapse-item__wrap {
  border: none !important;
}

.el-collapse-item__header {
  .el-collapse-item__arrow {
    font-size: 20px !important;
    color: var(--tg-heading-font-color) !important;
  }
}

.el-collapse-item__content {
  padding-bottom: 0 !important;
}

//     background: linear-gradient(-93.17deg, var(--tg-primary-color) -18.55%, #7E2AFD 163.09%);
.el-progress-bar__outer {
  background: rgba(255, 255, 255, 0.5) !important;
}

.el-progress-bar__inner {
  background: #fff !important;

  span {
    font-size: 10px !important;
  }
}

.el-progress-bar__innerText {
  font-size: 10px !important;
  margin-top: -6px !important;
  color: #000 !important;
}

.settings-form {
  .el-select__wrapper {
    margin-top: 4px;
  }

  .el-select__wrapper .el-select__selected-item span {
    color: #000 !important;
    font-size: 16px !important;
  }
}

.el-collapse-item__header {
  color: var(--tg-heading-font-color) !important;
  font-size: 18px !important;
}

.login-button-fixed {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 16px 16px 16px;
  display: none;
  z-index: 99;

  .btn {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}

@media only screen and (max-width: 600px) {
  .login-button-fixed {
    display: block;
  }
}

.el-table__cell {
  color: #fff !important;

}
.el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell {
  background: rgb(21, 23, 38) !important;
}


body, html, #app {
  overflow-x: hidden !important;
}

</style>
