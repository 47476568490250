<template>
  <div>
    <div class="main-text-container" v-if="!userEmail">
      <img src="@/assets/main/2.png" class="a-coin">
      <img src="@/assets/main/1.png" class="b-coin">
      <img src="@/assets/main/3.png" class="c-coin">

      <div class="inner-container">
        <h2 class="desktop" v-html="$t('post_v1')">
        </h2>

        <h2 class="mobile" v-html="$t('post_v2')">
        </h2>


        <router-link to="/login" class="btn-solid">{{$t('start_earning')}}</router-link>
      </div>
      <div class="earn-container">
        <div class="line-bg"></div>
        <div class="text-earn">
          <span>
           {{$t('earning_for_24')}}
          </span>
          <strong>$3195</strong>
        </div>
        <img src="@/assets/main/game_black_04.svg" class="game-black">

      </div>
    </div>


    <div class="manual-container" v-if="!userEmail">
      <h4 class="how-to-use-txt">{{$t('how_to_use')}}</h4>


      <div class="manual-wrapper">
        <div class="arrow-container">
          <img src="@/assets/main/arrow.svg">
          <div class="arrow-tmp"></div>
          <img src="@/assets/main/arrow.svg">

        </div>
        <div class="manual-img">
          <img src="@/assets/main/manual/1.png">
          <img src="@/assets/main/manual/2.png">

          <img src="@/assets/main/manual/3.png">

        </div>
      </div>

      <div class="footer-manual">
        <a target="_blank" href="https://streamsale.xyz/manual" class="link-manual">{{$t('go_to_manual')}}
          <el-icon>
            <TopRight/>
          </el-icon>
        </a>
        <router-link to="/login" class="btn-solid start-earn">{{$t('start_earning')}}</router-link>

      </div>
    </div>

    <div class="social-container" v-if="!userEmail">
      <h2>{{$t('social_desc')}}</h2>
      <div class="social-line">
        <a class="social-btn" target="_blank" href="https://t.me/streamsale_xyz">
          <div class="social-i">
            <i class="fab fa-telegram"></i>
          </div>
          <span>Telegram</span>
        </a>
        <a class="social-btn" target="_blank" href="https://chat.whatsapp.com/FjqCXaF2PdgEPRDtE3BUF5">
          <div class="social-i">
            <i class="fab fa-whatsapp"></i>
          </div>
          <span>WhatsApp</span>
        </a>
        <a target="_blank" href="https://t.me/streamsale_xyz" class="social-btn">
          <div class="social-i">
            <i class="fab fa-instagram"></i>
          </div>
          <span>Instagram</span>
        </a>
      </div>
    </div>


    <!--    <div class="off-er">-->
    <!--      <el-carousel style="width: 100%;  max-width: 1024px;" :interval="5000" :height="carouselHeight">-->
    <!--        <el-carousel-item v-for="(offer, index) in offers" :key="index">-->
    <!--          <div class="offer-preview" :style="{ 'background-image': 'url(' + offer.image + ')' }">-->
    <!--            <div class="text-offer">-->
    <!--              <h3>{{ $t(`header_offers[${index}].title`) }}</h3>-->
    <!--              <h5>{{ $t(`header_offers[${index}].subtitle`) }}</h5>-->
    <!--              <a-->
    <!--                  v-if="offer.link"-->
    <!--                  data-tag-->
    <!--                  data-cat="user-action" data-label="how-it-works"-->
    <!--                  :href="offer.link" target="_blank" :class="`btn header-banner-btn-${index}`"-->
    <!--                  style="margin-top: 20px; font-size: 18px">{{ $t(`header_offers[${index}].linkTxt`) }}</a>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </el-carousel-item>-->
    <!--      </el-carousel>-->
    <!--    </div>-->

    <div class="offers">
      <div class="title-offers">
        <h2 class="title" style="margin-bottom: 32px" v-if="!userEmail">{{$t('actual_offers')}} </h2>
        <h2 class="title" style="margin-bottom: 32px" v-else>{{$t('all_offers')}}</h2>

      </div>

      <div class="filters">
        <el-select style="max-width: 200px" v-model="selectedCurrency" :placeholder="$t('select_currency')" clearable>
          <el-option v-for="currency in uniqueCurrencies" :key="currency" :label="currency" :value="currency"/>
        </el-select>

        <div @click="togglePriceSort" class="sort-price">
          <span style="margin-right: 8px">{{ $t('price') }}</span>
          <el-icon v-if="priceSortOrder === 'desc'">
            <CaretBottom/>
          </el-icon>
          <el-icon v-if="priceSortOrder === 'asc'">
            <CaretTop/>
          </el-icon>
          <el-icon v-if="priceSortOrder === null">
            <DCaret/>
          </el-icon>

        </div>
      </div>
      <div class="offers-cards">
        <OfferCard
            :isAuth="!!userEmail"
            v-for="task in filteredAndSortedTasks" :key="task.id" :card="{ ...task }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import OfferCard from "@/components/OfferCard";
import {onMounted, ref, computed, onUnmounted} from 'vue';
import {db} from '@/firebase/init';
import {collection, getDocs, query, where} from 'firebase/firestore';
import {orderBy} from "lodash";
import {getAuth, onAuthStateChanged} from "firebase/auth";
// import {ElCarousel, ElCarouselItem} from 'element-plus';

export default {
  name: "OffersPage",
  components: {
    OfferCard,
    // ElCarousel,
    // ElCarouselItem
  },
  setup() {

    const auth = getAuth();
    const userEmail = ref('');

    const tasks = ref([]);
    const selectedCurrency = ref("");
    const priceSortOrder = ref(null);

    const carouselHeight = ref(window.innerWidth < 600 ? '290px' : '443px');

    const updateCarouselHeight = () => {
      carouselHeight.value = window.innerWidth < 600 ? '290px' : '443px';
    };

    onMounted(() => {
      window.addEventListener('resize', updateCarouselHeight);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', updateCarouselHeight);
    });

    const offers = ref([
      {
        i: 0,
        image: '/12.jpg',
        link: 'https://streamsale.xyz/manual',
      },
      {
        i: 1,
        image: '/2.jpg',
        link: 'https://streamsale.xyz/referral',
      },
      {
        i: 2,
        image: '/3.jpg',
        link: 'https://streamsale.xyz//offer/ZJKwVGR0mVCfQ2XNmUbZ',
      },
    ]);

    const uniqueCurrencies = computed(() => {
      const currencies = tasks.value.map(task => task.coin?.name || "");
      return [...new Set(currencies)].filter(currency => currency);
    });

    const fetchTasks = async () => {
      const q = query(collection(db, "tasks"), where("hide", "!=", true));
      const querySnapshot = await getDocs(q);
      tasks.value = querySnapshot.docs.map(doc => ({id: doc.id, ...doc.data()}));
    };

    onMounted(() => {
      fetchTasks()
      onAuthStateChanged(auth, (authUser) => {
        if (authUser) {
          userEmail.value = authUser.email;
        }
      });
    });

    const filteredAndSortedTasks = computed(() => {
      let filteredTasks = tasks.value;

      if (selectedCurrency.value) {
        filteredTasks = filteredTasks.filter(task => task.coin?.name === selectedCurrency.value);
      }

      if (priceSortOrder.value) {
        return orderBy(filteredTasks, ['price'], [priceSortOrder.value]);
      } else {
        return orderBy(filteredTasks, ['order'], ['asc']);

      }
    });

    const togglePriceSort = () => {
      if (priceSortOrder.value === null) {
        priceSortOrder.value = 'asc'
      } else if (priceSortOrder.value === 'asc') {
        priceSortOrder.value = 'desc'
      } else {
        priceSortOrder.value = null
      }
    };

    return {
      tasks,
      selectedCurrency,
      priceSortOrder,
      filteredAndSortedTasks,
      uniqueCurrencies,
      togglePriceSort,
      carouselHeight,
      offers,
      userEmail
    };
  },
};
</script>

<style scoped lang="scss">


.desktop {
  display: flex;
}

.mobile {
  display: none;
}

.social-line {
  display: flex;
  font-size: 20px;
  margin-top: 18px;
  text-transform: none;
  font-weight: 400;

  .social-btn {
    background: rgba(255, 255, 255, 0.2);
    width: 182px;
    height: 62px;
    border-radius: 32px;
    display: flex;
    color: #fff !important;
    align-items: center;
    margin: 0 8px;
    padding: 4px;

    .social-i {
      height: 54px;
      width: 54px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(255, 255, 255, 0.1);
      font-size: 28px;
    }
  }
}

.title-offers {

  display: flex;
  justify-content: flex-start;
  max-width: 1024px;
  margin: 32px auto 0 auto;
  width: calc(100% - 16px);
}

.offers {
  max-width: 1024px;
  margin: 32px auto;
  min-height: 420px;
  width: calc(100% - 16px);
}

.offers-cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 16px 16px;
}

@media only screen and (max-width: 600px) {
  .offers-cards {
    display: grid;
    grid-template-columns: 1fr 1fr !important;
    gap: 16px 16px;
    width: calc(100% - 16px);
    margin: 0 auto;
  }
}

.offer-preview {
  width: 100%;
  height: 420px;
  max-height: 432px;
  object-fit: cover;
  border-radius: 20px;
  max-width: 1024px;
  background-size: cover;
  background-position: center center;
  margin: 16px auto 32px auto;
  display: flex;
  flex-direction: column;
  padding: 72px;

  h3 {
    font-size: 42px;
    font-weight: bold;
    color: #fff;

  }

  h5 {
    font-size: 20px;
    color: #fff;
    font-weight: normal;
  }
}

.text-offer {
  width: 60%;
}

.off-er {
  display: flex;
  justify-content: center;
}


@media only screen and (max-width: 600px) {
  .offer-preview {
    max-width: 1024px;
    border-radius: 0 !important;
    padding: 16px;
    height: 290px;
    background-position: center center;

    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 0 !important;

    h3 {
      font-size: 24px;
      max-width: 90%;
    }

    h5 {
      font-size: 16px;
      max-width: 80%;
    }

    .btn {
      padding: 16px 32px;
    }
  }

  .text-offer {
    width: 80%;
  }
}

.filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  margin-bottom: 24px;
  margin-top: -18px;
  flex: 1;
}

.sort-price {
  height: 48px;
  color: var(--tg-heading-font-color);
  display: flex;
  background: var(--tg-second-bg) !important;
  border-radius: 20px !important;
  height: 48px !important;
  font-size: 16px !important;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.main-text-container {
  max-width: 1022px;
  width: calc(100% - 100px);
  position: relative;
  border-radius: 80px;
  height: 290px;
  margin: 0 auto;
  background: rgb(213, 88, 219);
  background: linear-gradient(171deg, rgba(213, 88, 219, 0.5) 0%, rgba(197, 32, 255, 0.1) 50%, rgba(239, 73, 103, 0.4976584383753502) 100%);
  padding: 2px;
  margin-top: 82px;
  margin-bottom: 82px;


  .inner-container {
    width: 100%;
    display: flex;
    background: rgb(21, 23, 37);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    border-radius: 80px;

    .btn-solid {
      width: 284px;
      height: 70px;
      border-radius: 36px;
      position: absolute;
      margin-top: 272px;

    }

    h2 {
      font-size: 60px;
      text-align: center;
      color: #fff;
    }
  }
}


@media only screen and (max-width: 880px) {

  .offers-cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 16px 16px;
    width: calc(100% - 16px);
    margin: 0 auto;
  }

  .desktop {
    display: none;
  }

  .social-container {
    margin-left:  8px !important;
    margin-right: 8px !important;
    h2 {
      font-size: 20px;
    }
  }

  .social-btn {
    width: 104px !important;
    height: 36px !important;
    padding: 3px !important;
    justify-content: flex-start !important;
    padding-right: 10px !important;
    gap: 4px !important;
    .social-i {
      display: flex;
      flex-shrink: 0;
      width: 31px !important;
      height: 31px !important;
      font-size: 15px !important;
    }
    font-size: 12px !important;
  }

  .how-to-use-txt {
    font-size: 28px !important;
    margin-left: 16px;
  }

  .manual-wrapper {
    overflow: scroll;
    max-width: 100%;


    &::-webkit-scrollbar {

      display: none;
    }
  }

  .arrow-container {
    min-width: 736px !important;
  }

  .arrow-tmp {
    width: 24% !important;
  }

  .manual-img {
    min-width: 736px !important;

    padding: 0 16px;
  }

  .mobile {
    display: flex;
  }

  .main-text-container {
    height: 370px !important;
    border-radius: 50px !important;
    width: calc(100% - 24px) !important;
    margin: 0 12px;
    justify-content: flex-start !important;
    margin-top: 72px;

    .inner-container {
      justify-content: flex-start !important;
      padding-top: 72px !important;
      border-radius: 50px !important;

      padding-left: 24px;
      padding-right: 24px;
      box-sizing: border-box;
    }

    .b-coin {
      width: 170px;
      margin-top: 176px;
      margin-left: 0px;
    }

    .a-coin {
      position: absolute;
      margin-top: -30px;
      margin-left: 0px;
    }

    .c-coin {
      width: 120px;
      right: 0;
      top: -40px;
    }

    h2 {
      font-size: 30px !important;
      max-width: 344px;
    }

    .game-black {
      width: 120px;
      right: -55px !important;
    }

    .earn-container {
      border-radius: 27px;
      width: 167px;
      bottom: 58px;
      height: 100px;

      right: 18px !important;

      .line-bg {
        width: 74px;
        box-shadow: 0px 0px 77px 35px rgb(255, 69, 193);
      }

      .text-earn {
        width: 100px;
        display: flex !important;
        flex-direction: column !important;
      }

      span {
        font-size: 12px !important;
      }

      strong {
        font-size: 34px !important;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .earn-container {
    right: 0 !important;


  }
}


.start-earn {
  width: 284px;
  height: 70px;
  border-radius: 36px;
}

.a-coin {
  position: absolute;
  margin-top: -50px;
  margin-left: 120px;
}

.b-coin {
  position: absolute;
  margin-top: 150px;
  width: 240px;
  margin-left: -100px;
}

.c-coin {
  position: absolute;
  right: -80px;
  top: -70px;
}


.manual-container {
  max-width: 1022px;
  width: 100%;
  margin: 132px auto 0 auto;
}

.social-container {
  max-width: 1022px;
  height: 186px;
  border-radius: 40px;
  margin: 0 auto;
  border: 2px solid rgba(255, 255, 255, 0.2);
  display: flex;
  margin-top: 72px;
  margin-bottom: 72px;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 100;
  text-transform: uppercase;
  flex-direction: column;
  color: #fff;
}

.earn-container {
  margin-top: -72px;
  position: absolute;
  border-radius: 40px;
  width: 246px;
  align-items: center;
  height: 149px;
  display: flex;
  right: -110px;
  border: 2px solid rgba(255, 255, 255, 0.2);

  .game-black {
    position: absolute;
    right: -94px;
  }

  .text-earn {
    position: absolute;
    width: 150px;
    margin-left: 28px;
    color: #fff;
    margin-top: 10px;

    span {
      font-size: 18px;
    }

    strong {
      font-size: 50px;
    }
  }
}

.manual-img {
  width: 100%;
  display: flex;
  justify-content: space-between;

  img {
    max-width: 300px;
    width: 30%;
  }
}

.arrow-container {
  display: flex;
  justify-content: center;

  img {
    width: 74px;
  }
}

.arrow-tmp {
  max-width: 300px;
  width: 30%;
}

.line-bg {
  box-shadow: 0px 0px 252px 61px rgba(255, 69, 193, 1);
  background: rgba(255, 69, 193, 0.6);
  margin-left: 44px;
  height: 0px;
  width: 140px;
}

.how-to-use-txt {
  font-size: 40px;
  margin-bottom: 0px;
}

.footer-manual {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.link-manual {
  color: #8D98FF;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
  align-items: center;
  display: flex;

  i {
    font-size: 20px;
    margin-left: 4px;
    margin-top: 28px;
    margin-bottom: 28px;
  }
}

.btn-solid {
  color: #fff !important;
}
</style>
