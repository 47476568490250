<template>
  <div class="header-main">
    <header id="header" class="header-layout1">
      <div :id="'sticky-header'" :class="{ 'menu-area transparent-header': true}">
        <div class="container custom-container">
          <div class="row">
            <div class="col-12">
              <div class="menu-wrap">
                <nav class="menu-nav">
                  <div class="logo">
                    <router-link to="/">
                      <img src="@/assets/logo.svg" class="logo-white" alt="Logo"/>
                      <img src="@/assets/logo-black.svg" class="logo-black" alt="Logo"/>
                    </router-link>
                  </div>
                  <div class="navbar-wrap main-menu d-none d-lg-flex">
                    <NavMenuTwo  v-if="userEmail"/>
                  </div>
<!--                  <div class="switcher-theme">-->
<!--                    &lt;!&ndash; Показать иконку солнца, когда тема темная &ndash;&gt;-->
<!--                    <img v-if="theme === 'dark'" @click="toggleTheme" src="@/assets/sunny-outline.svg">-->
<!--                    &lt;!&ndash; Показать иконку луны, когда тема светлая &ndash;&gt;-->
<!--                    <img v-if="theme === 'light'" @click="toggleTheme" src="@/assets/moon-outline.svg">-->
<!--                  </div>-->

                  <div class="header-action" v-if="userEmail">
                    <LanguageSwitcher isMobile/>
                  </div>
                  <div v-if="!userEmail" style="display: flex">

                    <LanguageSwitcher isMobile/>
                  <router-link
                      data-tag data-cat="navigate" data-label="register" data-value="header"
                      style="height: 42px; font-size: 16px !important; margin-left: 8px; padding: 0 18px" class="btn btn-desk" v-if="!userEmail" to="/login">
                    {{$t('sign-in')}}
                  </router-link>
                  </div>
                  <div v-if="userEmail" @click="setIsActive(true)" class="mobile-nav-toggler">
                    <i class="fas fa-bars"></i>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <Sidebar :isUserEmail="!!userEmail" :isActive="isActive" :setIsActive="setIsActive"/>
  </div>
</template>

<script>
import {onMounted, ref} from 'vue';
import NavMenuTwo from './NavMenuTwo';
import Sidebar from './SidebarMenu';
import LanguageSwitcher from "@/components/LanguageSwitcher";
import {getAuth, onAuthStateChanged} from "firebase/auth";

export default {
  name: 'HeaderTwo',
  components: {
    LanguageSwitcher,
    NavMenuTwo,
    Sidebar
  },
  setup() {
    const isActive = ref(false);
    const setIsActive = (value) => {
      isActive.value = value;
    };

    const auth = getAuth();
    const userEmail = ref('');

    // Реактивное свойство для темы
    const theme = ref('dark'); // по умолчанию темная тема

    // Функция переключения темы
    const toggleTheme = () => {
      theme.value = theme.value === 'dark' ? 'light' : 'dark';
      localStorage.setItem('theme', theme.value);
      document.body.classList.toggle('white-theme', theme.value === 'light');
    };

    onMounted(() => {
      // Проверяем сохраненную тему в localStorage
      // const savedTheme = localStorage.getItem('theme');
      // if (savedTheme) {
      //   theme.value = savedTheme;
      // }
      // // Устанавливаем класс для body в зависимости от темы
      // if (theme.value === 'light') {
      //   document.body.classList.add('white-theme');
      // } else {
      //   document.body.classList.remove('white-theme');
      // }

      document.body.classList.remove('white-theme');


      onAuthStateChanged(auth, (authUser) => {
        if (authUser) {
          userEmail.value = authUser.email;
        }
      });
    });

    return {
      isActive,
      userEmail,
      setIsActive,
      theme,
      toggleTheme
    };
  }
};

</script>
<style lang="scss" scoped>
.header-main {
  height: 98px;
  border-bottom: 1px solid var(--tg-second-bg);
}

.btn::after {
  margin-left: 8px;
}

@media only screen and (max-width: 600px) {
  .btn-desk {
    display: none;
  }
}

.switcher-theme {
  @media only screen and (max-width: 992px) {
    display: flex;
    justify-content: flex-end;
    flex: 1;
    padding-right: 18px;
  }
  img {
    cursor: pointer;
    width: 28px;
    height: 28px;
  }
}

//.header-action {
//  display: flex;
//  cursor: pointer;
//  align-items: center;
//}
</style>
