export function trackElementEvent(event) {
    const element = event.target.closest('[data-tag]');
    if (element) {
        const category = element.getAttribute('data-cat') || 'default_category';
        const label = element.getAttribute('data-label') || 'default_label';
        const value = element.getAttribute('data-value') || 1;

        console.log({
            'event_category': category,
            'event_label': label,
            'value': value
        })

        if (typeof window.gtag !== 'undefined') {
            window.gtag('event', 'click', {
                'event_category': category,
                'event_label': label,
                'value': value
            });
        } else {
            console.warn('gtag is not defined');
        }
    }
}
