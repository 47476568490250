<template>
  <div class="user-profile">
    <div class="user-title">
      <h2 class="title">{{ $t('referral') }}</h2>

      <h4 style="margin-top: 12px">
        {{ $t('referral_page.title') }}
      </h4>
      <div class="desc-block">
        <span>
           {{ $t('referral_page.desc') }}
        </span>
      </div>
    </div>
    <h4 style="margin-top: 32px">   {{ $t('referral_page.link') }}</h4>
    <div class="referral-link" @click="copyReferralLink">
      <div class="link-rf">
        <span>{{ referralLink }}</span>
      </div>
      <div class="icon-block" data-tag  data-cat="referral" data-label="copy-referral" data-value="link-btn">
        <el-icon>
          <CopyDocument/>
        </el-icon>
      </div>
    </div>

    <div class="stat-referral">
      <h2 v-html="$t('referral_page.invited_referrals', { count: referrals.length })"></h2>
    </div>

    <div v-if="referrals.length > 0">
      <div v-for="(referral, index) in referrals" :key="referral.id" class="referral-user">
        <div>
          <strong>{{ index + 1 }}. </strong>
          <span>{{ referral.userUid }}</span>
        </div>
        <div>
          {{ $t('referral_page.you_earn') }}
          <span>{{ referral.earnings || '-' }}</span>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { getAuth } from 'firebase/auth';
import { ElMessage } from "element-plus";
import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";
import {useI18n} from "vue-i18n/dist/vue-i18n"; // Firebase Firestore методы

export default {
  name: "ReferralPage",
  setup() {
    const userUid = ref(null);
    const referrals = ref([]);
    const auth = getAuth();
    const db = getFirestore(); // Инициализация Firestore
    const user = auth.currentUser;
    const { t } = useI18n();

    userUid.value = user.uid;

    // Создаем ссылку для приглашения с уникальным ref
    const referralLink = computed(() => {
      const baseUrl = 'https://streamsale.xyz/';
      return `${baseUrl}?ref=${userUid.value}`;
    });

    // Функция для копирования ссылки
    const copyReferralLink = () => {
      navigator.clipboard.writeText(referralLink.value)
          .then(() => {
            ElMessage({
              message: t('referral_page.link_copy'),
              type: 'success',
              duration: 3000
            });
          })
          .catch(err => {
            ElMessage.error(t('referral_page.copy_error'));
            console.error('Ошибка при копировании: ', err);
          });
    };

    // Получение рефералов из Firestore
    const fetchReferrals = async () => {
      try {
        const q = query(collection(db, "referrals"), where("refUid", "==", userUid.value)); // Получаем рефералов по userUid
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          referrals.value.push({ id: doc.id, ...doc.data() }); // Добавляем каждый документ в массив
        });
      } catch (error) {
        console.error('Ошибка при получении рефералов: ', error);
      }
    };

    // Загружаем список рефералов при монтировании компонента
    onMounted(() => {
      fetchReferrals();
    });

    return {
      userUid,
      referralLink,
      referrals,
      copyReferralLink
    };
  }
}
</script>

<style scoped lang="scss">
.user-profile {
  max-width: 1024px;
  margin: 32px auto;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
}

.desc-block {
  background: var(--tg-primary-bg);
  padding: 16px;
  margin-top: 22px;
  border-radius: 18px;
  color: var(--tg-heading-font-color);
}

h2 {
  color: var(--tg-heading-font-color);
}

.referral-link {
  min-height: 60px;
  padding: 4px 6px 4px 30px;
  cursor: pointer;
  background: #fff;
  border: 0;
  border-radius: 50px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  max-width: 600px;
  color: #000000;
  margin: 16px 0;
  letter-spacing: -0.48px;
}

.icon-block {
  width: 48px;
  height: 48px;
  margin-left: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(-93.17deg, var(--tg-primary-color) -18.55%, #7E2AFD 163.09%);
  color: #fff;
  flex-shrink: 0;
  border-radius: 50%;
}

.stat-referral {
  display: flex;
  justify-content: center;
  margin-top: 42px;
}

.link-rf {
  overflow-wrap: break-word;
  overflow: hidden;
}

.referral-user {
  display: flex;
  justify-content: space-between;
  background: var(--tg-primary-bg);
  padding: 16px;
  margin-top: 8px;
  color: var(--tg-heading-font-color);
  border-radius: 18px;
}

.balances {
  display: flex;
}

.color-strong {}
</style>
