<template>
  <div class="user-profile" v-loading="loader">
    <div class="settings-container" v-if="!loading && !isSuccess">
      <div class="user-profile-info" style="max-width: 380px">
        <div class="user-title">
          <h2 class="title">{{ $t('upload_tiktok_video') }}</h2>
        </div>

        <div class="creative-card">
          <CreativeCard :src="currentVideo.href" :src_preview="currentVideo.preview_href" v-if="currentVideo"/>
        </div>
      </div>
      <div class="user-profile-info" style="margin-top: 64px">
        <div class="main-form settings-form">
          <div class="group-input" v-if="profiles.length">
            <label class="main-label">{{ $t('select_profile') }}</label>
            <el-select v-model="selectedProfileId" placeholder="Select profile" class="main-input">
              <el-option
                  v-for="profile in profiles"
                  :key="profile.id"
                  :label="profile.username"
                  :value="profile.id"
              >
                <div class="profile-option">
                  <img :src="profile.avatar_url" class="profile-avatar" alt="Avatar">
                  <span>{{ profile.username }}</span>
                  <span class="profile-type"> - [{{ profile.type || 'TIKTOK' }}]</span>
                </div>
              </el-option>
            </el-select>
          </div>
          <div class="group-input" v-if="profiles.length">
            <label class="main-label">{{ $t('description_and_hashtags') }}</label>
            <textarea :rows="6" class="main-input textarea" v-model="descriptionHashtags"/>
            <div
                data-tag
                data-cat="user-action" data-label="upload-edit" :data-value="task.title"
                v-if="selectedProfileType !== 'YOUTUBE' && selectedProfileType !== 'VK'"
                class="upload-edit save-password upload-video-creative-with-edit" @click="publishToInbox">{{ $t('Upload with edit') }}
            </div>
            <button
                data-tag
                data-cat="user-action" data-label="upload" :data-value="task.title"
                class="btn-solid upload-video-creative" style="margin-top: 12px" @click="save">{{ $t('Upload') }}
            </button>

            <div class="manual">
              <div style="margin-top: -20px">
                <div class="dot-container">
                  <PulseDot/>
                </div>
                <span style="margin-left: 42px" v-html="$t('create_video.upload_with_edit_instruction')"></span>
              </div>
            </div>
          </div>
          <div v-else class="container-center connect-account">
            <div @click="connectAccount" v-if="!loader">
              <span class="txt-align">{{
                  $t('in_order_to_upload_and_share_your_videos_on_our_platform_you_need_to_connect_your_tiktok_account')
                }}</span>
              <button
                  data-tag
                  data-cat="connect-account" data-label="connect-account-creative" data-value="tiktok"
                  class="btn-solid connect-tiktok-create-video">{{ $t('connect_tiktok_account') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <template v-else>
      <div class="direct-container settings-container" v-if="uploadType === 'direct'">
        <div style="display: flex; justify-content: center; width: 100vw">
          <div v-if="!isSuccess" class="loader-container">
            <div v-loading="true" class="loading-icon"></div>
            {{ $t('preparing_video_for_upload_please_wait_') }}
          </div>
          <div v-if="isSuccess" class="success-container">
            <div class="loader-container">
              <i class="far fa-check-circle success-icon loading-icon"></i>
              <span class="notify-content">   {{ $t('create_video.loading_video') }}</span>
            </div>
            <button class="btn-solid create-video-go-to-profile" @click="toProfile">{{ $t('go_to_profile') }}</button>
          </div>
        </div>
      </div>
      <div class="settings-container instructions-settings" v-else>
        <h2>{{
            $t('after_uploading_your_video_you_will_receive_a_notification_in_tiktok_please_click_on_your_inbox_notifications_to_continue_the_editing_flow_and_complete_your_post_')
          }}</h2>
        <div class="instructions">
          <div class="instructions-li">
            <img src="@/assets/manual/1.jpg">
            <span><strong>1.</strong> {{ $t('open_the_tiktok_app') }}</span>
          </div>
          <div class="instructions-li">
            <img src="@/assets/manual/2.jpg">
            <span><strong>2.</strong> {{ $t('go_to_the_inbox_tab_and_click_on_system_notifications') }}</span>
          </div>
          <div class="instructions-li">
            <img src="@/assets/manual/3.jpg">
            <span><strong>3.</strong> {{ $t('click_on_the_notification_to_edit_the_video') }}</span>
          </div>
          <div class="instructions-li">
            <img src="@/assets/manual/4.jpg">
            <span><strong>4.</strong> {{ $t('edit_and_publish_the_video') }}</span>
          </div>
        </div>
        <div class="please-box">
          <h3>{{ $t('please_add_music_a_filter_or_text_to_the_video_') }}</h3>
        </div>

        <div v-if="isSuccess" class="success-container">
          <div class="loader-container">
            <i class="far fa-check-circle success-icon loading-icon"></i>
            <span class="notify-content">{{
                $t('your_video_has_been_sent_you_will_receive_a_notification_on_tiktok_within_a_few_minutes')
              }}</span>
          </div>
          <button
              data-tag
              data-cat="navigate" data-label="profile" data-value="from-upload"
              class="btn-solid create-video-go-to-profile-2" @click="toProfile">{{ $t('go_to_profile') }}
          </button>
        </div>
        <div v-else class="loader-container">
          <div v-loading="true" class="loading-icon"></div>
          {{ $t('preparing_video_for_upload_please_wait_') }}
        </div>
      </div>
    </template>
    <NotificationModal v-if="showModal" @close="showModal = false"/>
  </div>
</template>


<script>
import {db} from '@/firebase/init';
import {doc, getDoc} from 'firebase/firestore';
import {onMounted, ref, watch} from "vue";
import CreativeCard from "@/components/CreativeCard";
import {useRoute, useRouter} from "vue-router";
import {getAuth} from "firebase/auth";
import axios from "@/plugins/axios";
import {ElNotification} from "element-plus";
import {fetchTikTokProfiles} from "@/firebase/queries";
import PulseDot from "@/components/PulseDot";
import NotificationModal from "@/components/NotificationModal";

export default {
  name: "CreateVideoPage",
  components: {NotificationModal, PulseDot, CreativeCard},
  setup() {
    const task = ref({});
    const currentVideo = ref(null);
    const descriptionHashtags = ref(null);
    const loading = ref(false);
    const profiles = ref([]);
    const selectedProfileId = ref(null);
    const selectedProfileType = ref('');
    const loader = ref(true);
    const isSuccess = ref(false);
    const uploadType = ref('inbox');
    const showModal = ref(false);

    const route = useRoute();
    const router = useRouter();

    const taskId = route.params.id;
    const videoUID = route.params.videoId;

    const fetchTask = async () => {
      const docRef = doc(db, "tasks", taskId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        task.value = {id: docSnap.id, ...docSnap.data()};
        const video = task.value.videos.find(v => v.id === videoUID);

        console.log(task.value, 'task.value')
        currentVideo.value = video;
      } else {
        console.log("Задача не найдена!");
      }
    };

    const auth = getAuth();
    const user = auth.currentUser;

    onMounted(async () => {
      loader.value = true;
      const userUid = user.uid;
      profiles.value = await fetchTikTokProfiles(userUid);
      if (profiles.value.length > 0) {
        selectedProfileId.value = profiles.value[0].id;
        selectedProfileType.value = profiles.value[0].type;
      }
      loader.value = false;
    });

    watch(selectedProfileId, (newValue) => {
      const selectedProfile = profiles.value.find(profile => profile.id === newValue);
      if (selectedProfile) {
        selectedProfileType.value = selectedProfile.type || 'TIKTOK';
      }
    });

    const publish = async (path) => {
      loading.value = true;

      try {
        const token = await user.getIdToken();

        const data = {
          token,
          offerId: taskId,
          creativeId: videoUID,
          videoUrl: currentVideo.value.href,
          profileId: selectedProfileId.value   // Передаем тип выбранного профиля
        };

        data.title = descriptionHashtags.value || 'streamsale';

        await axios.post('/content/' + path, {...data});

        setTimeout(() => {
          loading.value = false;
          isSuccess.value = true;
          showModal.value = true
        }, 4000);

      } catch (e) {
        ElNotification({
          title: 'Error',
          message: 'An error occurred while publishing',
          type: 'error',
        });
        loading.value = false;
      }
    };

    const save = async () => {
      uploadType.value = 'direct';
      if (selectedProfileType.value === 'VK') {
        await publish('direct-vk');
      } else if (selectedProfileType.value === 'YOUTUBE') {
        await publish('direct-youtube');
      } else {
        await publish('direct');
      }
    };

    const publishToInbox = async () => {
      uploadType.value = 'inbox';
      await publish('inbox');
    };

    const toProfile = () => {
      router.push('/profile');
    };

    const connectAccount = async () => {
      const token = await user.getIdToken();
      axios.post('/tiktok-hash', {token}).then(({data}) => {
        localStorage.setItem('redirectAfterLogin', route.fullPath);
        window.location.href = process.env.VUE_APP_TIKTOK_URL + '/tiktok-init/?hash=' + data;
      });
    };

    onMounted(fetchTask);

    return {
      task,
      loader,
      currentVideo,
      profiles,
      selectedProfileId,
      selectedProfileType, // Возвращаем выбранный тип профиля
      isSuccess,
      connectAccount,
      descriptionHashtags,
      loading,
      uploadType,
      publishToInbox,
      toProfile,
      save,
      showModal,
      taskId,
    };
  }
};
</script>

<style scoped lang="scss">
.user-profile {
  max-width: 1024px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.user-title {
  display: flex;
  justify-content: flex-start;
  width: 326px;
}

.offer-description, .offer-label {
  display: flex;
  font-size: 17px;
  color: var(--tg-second-color);
  font-weight: 300;
  font-family: Roboto;
}

.offer-participants {
  max-width: 420px;
  padding: 12px 0;
  border-bottom: 1px solid var(--tg-second-bg);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.direct-container {
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.settings-form {
  max-width: 420px;
}

.icon-info {
  margin-right: 8px;
}

.participants-count {
  color: var(--tg-heading-font-color);
  font-weight: 600;
  align-items: center;
  display: flex;
  font-size: 15px;
  font-family: Roboto;
}

.icon-info.hot {
  width: 24px;
  height: 24px;
  margin-right: 4px;
}

.settings-container {
  display: flex;
  margin-top: 32px;
  margin-bottom: 32px;
  flex-direction: row;
}

.user-profile-info {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
}

.btn-container {
  margin-top: 32px;
  display: flex;
  justify-content: flex-start;
}

.logout {
  color: #EA5757;
  font-size: 18px;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    color: red;
  }
}

.success-icon {
  font-size: 44px;
  color: #E275FF;
}

.logout-container {
  display: flex;
  justify-content: flex-start;
  margin-top: 64px;
  flex: 1;
}

.change-pass-txt {
  margin-top: 72px;
}

.group-input {
  margin-top: 32px;
}

.save-password {
  margin-top: 32px;
}

.download-and-publish {
  margin-top: 32px;
}

.instructions {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin: 12px auto;
  background: var(--tg-primary-bg);
  padding: 32px 16px;
  border-radius: 22px;

  img {
    max-width: 200px;
    margin: 0 16px;
    border-radius: 22px;
    overflow: hidden;
  }
}

.instructions-settings {
  margin: 0 auto;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 60px;
  text-align: center;

  h2 {
    max-width: 640px;
    width: 90%;
    margin-bottom: 24px;
    font-size: 18px;
    color: var(--tg-heading-font-color);
    font-family: Roboto;
    font-weight: 400;
  }
}

.loader-container {
  background: var(--tg-primary-bg);
  margin-top: 32px;
  max-width: 640px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--tg-heading-font-color);
  width: 90%;
  height: 86px;
  padding: 0 8px;

  box-sizing: border-box;
  border-radius: 20px;
  margin-bottom: 12px;
}

.loading-icon {
  margin-right: 8px;
  width: 80px;
  margin-left: -30px;
  position: relative;
}

@media only screen and (max-width: 600px) {
  .instructions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 12px auto;
    flex-direction: column;

    img {
      width: 200px;
      margin: 0 16px;
    }
  }

  .success-container {
    padding: 0 12px;
    flex-direction: column;

    .loader-container {
      width: 100% !important;
      margin-left: 0 !important;
      margin-right: 0 !important;
      margin-bottom: 12px !important;
    }

    .btn-solid {
      width: 100%;
    }
  }

  .settings-container {
    padding-top: 22px !important;
  }

  .please-box {
    max-width: 70%;
  }

  .instructions-li {
    margin-bottom: 32px !important;
  }

  .loader-container {
    text-align: left;
  }

  .loading-icon {
    margin-left: 0;
  }

  .user-profile {
    margin: 0 auto;
    width: 100%;
  }
}

.please-box h3 {
  font-size: 18px;
  margin-top: 32px;
}

@media only screen and (max-width: 600px) {
  .settings-container {
    flex-direction: column;
    padding: 0 8px 98px 8px;
    padding-top: 0 !important;
    align-items: center;
  }

  .settings-form {
    margin: 0 auto;
    width: 100%;
  }

  .creative-card {
    margin: 0 auto;
  }

  .instructions {
    width: 90%;
  }

  .instructions-li {
    max-width: 100% !important;
    align-items: center !important;
  }

  .user-profile-info {
    display: flex;
    align-items: center;

    .title {
      margin-bottom: 26px;
    }
  }

  .txt-align {
    margin-top: 16px !important;
  }
}

.txt-align {
  display: flex;
  margin-bottom: 32px;
  margin-top: -32px;
}

.connect-account {
  display: flex;
  padding: 0 16px;
}

.instructions-li {
  display: flex;
  flex-direction: column;
  max-width: 200px;
  justify-content: flex-start;
  color: var(--tg-heading-font-color);
  height: 100%;
  margin: 0 22px;

  img {
    margin-bottom: 22px;
  }
}

.success-container {
  display: flex;
  align-items: center;

  .loader-container {
    margin: 0 12px;
  }


  margin-top: 32px;
  margin-bottom: 12px;
}

.notify-content {
  max-width: 70%;
}

.upload-edit {
  line-height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--tg-white);
  cursor: pointer;
  text-align: center;
  font-size: 16px;
  width: 100%;
  height: 58px;
  border-radius: 22px;
  background: var(--tg-primary-bg);

  &:hover {
    background: var(--tg-primary-color);
    color: var(--tg-white);
    border-color: var(--tg-primary-color);
  }
}

.profile-avatar {
  width: 32px;
  height: 32px;
  margin-right: 10px;
}

//.profile-option:hover {
//  background: red !important;
//}

.el-select.main-input {
  display: grid;
}

.dot-container {
  width: 40px;
  height: 40px;
  padding-top: 20px;
  padding-right: 10px;
  margin-left: -14px;
}

.pulsating-circle {
  margin-top: 20px;
}


.manual {
  padding: 0px 16px 16px 16px;
  margin-top: 16px;
  font-size: 15px;
  border-radius: 12px;
  color: var(--tg-heading-font-color);
  background: var(--tg-primary-bg)
}
</style>
