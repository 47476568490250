<template>
  <div class="login-page center-container" v-loading="loading">
    <h2 class="title orange">{{$t('registration')}}</h2>
    <div class="main-form login-page-form">
      <div class="google-login" @click="loginWithGoogle">
        <img src="@/assets/google.svg"/>
        <span>{{ $t('btn_google') }}</span>
      </div>
      <div class="or-line">
        <div class="line-border"></div>
        <div class="line-text">{{ $t('or') }}</div>
      </div>

      <div class="group-input">
      <label class="main-label">{{$t('email')}}</label>
      <input class="main-input"  v-model="email"  />
      </div>
      <div class="group-input">
      <label class="main-label">{{$t('password')}}</label>
      <input class="main-input" type="password"  v-model="password"  />
      </div>

      <div class="group-input">
        <label class="main-label">{{$t('confirm_password')}}</label>
        <input class="main-input" type="password"  v-model="confirmPassword"/>
      </div>
      <button data-tag data-cat="auth" data-label="registration" data-value="registration-page" class="btn-solid login-button registration-button-click" @click="register">{{$t('registration')}}</button>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import {auth, db} from '@/firebase/init';
import {createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup} from 'firebase/auth';
import { ElNotification } from 'element-plus'
import {useRouter} from "vue-router";
import {addDoc, collection} from "firebase/firestore";
import dayjs from "dayjs";
import {fetchTikTokProfiles} from "@/firebase/queries";

export default {
  name: "LoginIndex",
  setup() {
    const email = ref('');
    const password = ref('');
    const confirmPassword = ref('');
    const router = useRouter();
    const loading = ref(false)
    const provider = new GoogleAuthProvider();

    const register = () => {
      loading.value = true
      if(confirmPassword.value !== password.value) {
        ElNotification({
          title: 'Error',
          message: 'Password mismatch',
          type: 'error',
        })
        return
      }

      if(confirmPassword.value.length < 6) {
        ElNotification({
          title: 'Error',
          message: 'Password must be more than 6 characters',
          type: 'error',
        })
        return
      }

      createUserWithEmailAndPassword(auth, email.value, password.value)
          .then(async (userCredential) => {
            console.log(userCredential);
            const referral = localStorage.getItem('ref');

              const userUid = userCredential.user.uid;

              if (referral) {
                try {
                  await addDoc(collection(db, "referrals"), {
                    userUid: userUid,
                    refUid: referral,
                    date: dayjs().format()
                  });

                  console.log("Referral записан успешно!");
                } catch (error) {
                  console.error("Ошибка при записи реферальных данных в Firebase: ", error);
                }
            }

            ElNotification({
              title: 'Success!',
              message: 'Registration successfully completed!',
              type: 'success',
            })
            loading.value = false

            router.push('/connect');
          })
          .catch((error) => {
            loading.value = false

            console.log(error)
            ElNotification({
              title: 'Error',
              message: JSON.stringify(error).includes('invalid-email') ? 'Enter a valid email' : error,
              type: 'error',
              duration: 5566666
            })
          });
    };

    const loginWithGoogle = () => {
      signInWithPopup(auth, provider)
          .then(async (result) => {
            const user = result.user;
            const userUid = user.uid;
            const prof = await fetchTikTokProfiles(userUid);

            if (prof && prof.length) {
              router.push('/offers');
            } else {
              router.push('/connect');
            }
          })
    };

    return {loading, email, password, confirmPassword, register, loginWithGoogle };
  }
}
</script>

<style scoped lang="scss">
.login-page {
  margin-top: 80px;
  margin-bottom: 80px;
}
.login-page-form {
  max-width: 382px;
  width: calc(100% - 16px);

  .group-input {
    width: 100%;
    margin-top: 32px;
  }
}

.login-button {
  margin-top: 24px;
}


.registration-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  margin-top: 32px;
}

.login-reg-button {
  height: 30px;

}


.google-login {
  font-size: 18px;
  height: 58px;
  background: rgba(255, 255, 255, 0.15);
  color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.4s ease;
  cursor: pointer;
  border-radius: 22px;
  padding: 0 16px;
  text-decoration: none !important;
  border: none;
  margin-top: 24px;
  margin-bottom: 24px;

  img {
    margin-right: 8px;
  }

}

.or-line {
  display: flex;
  justify-content: center;
  flex-direction: column-reverse;
  align-items: center;

  .line-border {
    background: rgba(255, 255, 255, 0.15);
    height: 1px;
    width: 100%;
  }

  .line-text {
    width: 52px;
    height: 24px;
    background: #0b0b0b;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    position: relative;
    margin-bottom: -13px;
    font-size: 18px;
    color: #92939E;
  }
}
</style>
